import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

  state = {
    IDCompte:null
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  componentDidMount(){
    const splitedUrl = window.location.href.split('/')
    const IDCompte = splitedUrl[splitedUrl.length - 1]
    if(parseInt(IDCompte)>0){
      console.log(IDCompte)
      this.setState({ IDCompte:IDCompte })
    }
  }

  render() {
    const {themeType, navStyle, pathname, IDEmploye, IDCompteAtlas, IDClientStaffSession, IsAccesSuiviTechnique} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          <UserProfile/>
            <AppsNavigation/>
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'lite'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>

              <Menu.Item key="commande">
                <Link to="/commande"><i className="icon icon-shopping-cart"/>
                  <IntlMessages id="sidebar.commandes"/></Link>
              </Menu.Item>

              <Menu.Item key="bon_livraison">
                <Link to="/bon_livraison"><i className="icon icon-attachment"/>
                  <IntlMessages id="sidebar.BL"/></Link>
              </Menu.Item>

              <Menu.Item key="facture">
                <Link to="facture"><i className="icon icon-orders"/>
                  <IntlMessages id="sidebar.Factures"/></Link>
              </Menu.Item>

              <Menu.Item key="adresses">
                <Link to="/adresses"><i className="icon icon-map-google"/>
                  <IntlMessages id="sidebar.Adresses"/></Link>
              </Menu.Item>

              <SubMenu key="documents"  title={
                        <span>
                        <i className="icon icon-ckeditor"/>
                         <IntlMessages id="sidebar.documents"/>
                         </span>}>

                        <Menu.Item key="document_ft">
                          <Link to="/document_ft"><i className="icon icon-ckeditor"/>
                            <IntlMessages id="sidebar.documents.FT"/></Link>
                        </Menu.Item>

                        <Menu.Item key="document_rse">
                          <Link to="/document_rse"><i className="icon icon-ckeditor"/>
                            <IntlMessages id="sidebar.documents.RSE"/></Link>
                        </Menu.Item>

                        <Menu.Item key="document_marketing">
                          <Link to="/document_marketing"><i className="icon icon-ckeditor"/>
                            <IntlMessages id="sidebar.document_marketing"/></Link>
                        </Menu.Item>

                </SubMenu>


                <Menu.Item key="suivi_technique">
                  <Link to="/suivi_technique"><i className="icon icon-select"/>
                    <IntlMessages id="sidebar.suivi_technique"/></Link>
                </Menu.Item>

              <Menu.Item key="administration">
                <Link to="/administration"><i className="icon icon-auth-screen"/>
                  <IntlMessages id="sidebar.Administration"/></Link>
              </Menu.Item>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {IDEmploye, IDCompteAtlas} = auth
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname, IDEmploye, IDCompteAtlas}
};
export default connect(mapStateToProps)(SidebarContent);
