

import {
  STAFF_SESSION_CLIENT,
  CLIENTS_DATA,
  CLIENTS_DATA_CLEAN,
} from "../../constants/ActionTypes";



export const INIT_STATE = {
  clients_LIST: [],
  IDClientStaffSession: null,
  IDCompteAtlas: null,
  client_name: null,
  clients_LIST_OFFSET:0,
  clients_LIST_QUANTITY:60,
};



export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case CLIENTS_DATA: {
      return {
        ...state,
        clients_LIST: removeDuplicates(state.clients_LIST.concat(action.payload)),
        clients_LIST_OFFSET:action.offset,
      };
    }


    case CLIENTS_DATA_CLEAN: {
      return {
        ...state,
        clients_LIST: [],

      };
    }

    case STAFF_SESSION_CLIENT: {
      console.log(action.payload)
      return {
        ...state,
        IDClientStaffSession: action.payload.IDClientStaffSession,
        IDCompteAtlas: action.payload.IDCompteAtlas,
        client_name:action.payload.client_name
      };
    }

    default:
      return state;
  }
}


function removeDuplicates(json) {
    const jsonObject = json.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return uniqueArray
}
