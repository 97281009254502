import axios from 'axios';

var axiosConfig = {
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  }
}

if(axiosConfig.baseURL === undefined){
  if (process.env.REACT_APP_ENV === "FCD"){
    axiosConfig.baseURL = 'https://stagingpro.france-culinaire.com/v1/fcd'
  }
  else if (process.env.REACT_APP_ENV === "HYDRAPRO"){
    axiosConfig.baseURL = 'http://172.16.254.100/v1/hydrapro'

  }
  else if (process.env.REACT_APP_ENV === "HYDRACHIM"){
    axiosConfig.baseURL = 'http://172.16.254.100/v1/hydrachim'
  }
  else if (process.env.REACT_APP_ENV === "KEMIX"){
    axiosConfig.baseURL = 'http://172.16.254.100/v1/kemix'
  }
  if (process.env.REACT_APP_ENV === "developement"){
    axiosConfig.baseURL = 'http://127.0.0.1:8070'
  }
}

//console.log(process.env.REACT_APP_URL)
//console.log(axiosConfig.baseURL)

export default axios.create(axiosConfig);


/*
import FCD from '../environment/FCD.json'
import HYDRAPRO from '../environment/HYDRAPRO.json'
import HYDRACHIM from '../environment/HYDRACHIM.json'
import KEMIX from '../environment/KEMIX.json'
import DEVELOPEMENT from '../environment/DEVELOPEMENT.json'
var imported = null


switch (process.env.REACT_APP_ENV || 'developement') {
  case 'FCD': imported = FCD;
  case 'HYDRAPRO': imported = HYDRAPRO;
  case 'HYDRAPRO': imported = HYDRAPRO;
  case 'HYDRAPRO': imported = HYDRAPRO;
  case 'developement': imported = DEVELOPEMENT;
  break
}


console.log(imported)
console.log(process.env.REACT_APP_ENV)
*/
