import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import { Redirect} from 'react-router-dom'
import {userSignOut} from "appRedux/actions/Auth";

class UserProfile extends Component {

  state = {
    redirect:false
  }

  unsetSession = () => {
    console.log("test")
    this.setState({redirect:true})
    window.location.href='/clients'
    //this.props.history.push('/clients')
    this.props.unsetStaffSession()
  }


  render() {
    const {authUser, client_name} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li href='/clients' onClick={() => this.unsetSession()}> Fermer session
        </li>
      </ul>
    );

    if(this.state.redirect){
      return <Redirect push to="/clients" />;
    }

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                <span className="gx-avatar-name ">
                  {client_name ? client_name : "Chargement"}
                  <i className="icon icon-chevron-down gx-fs-xxxs gx-ml-1"/>
                </span>
        </Popover>
      </div>
    )
  }
}


const unsetStaffSession = () => {
  //console.log(IDClient)
  //this.props.history.push('/');
  return (dispatch) => {
    dispatch({type:'STAFF_SESSION_CLIENT', payload:{IDClientStaffSession:null,client_name:null}})
    dispatch({type:'CLEAR_ONLY_DATA'})
  }
};

const mapStateToProps = ({auth, clients}) => {
  const {client_name} = clients
  const {authUser} = auth;
  return {authUser, client_name}
};

export default connect(mapStateToProps, {
  userSignOut,
  unsetStaffSession
})(UserProfile);
