import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Select} from "antd";
import {userSignOut, userChangerMutliClient} from "appRedux/actions/Auth";
import static_url from 'util/StaticInfo'
import axios from 'util/Api'

const Option      = Select.Option;

var childrenClient = []

class UserProfile extends Component {

  handleChangeClient = (IDClient) => {

    // On update le client lié à ce compte
    axios.post('profile/multiClient', {
        IDClient: IDClient,
      }
    ).then(({data}) => {
      console.log(data)

      if (data.success) {
        this.props.userChangerMutliClient()
      } else {
        console.log(data.error)
      }

    }).catch(function (error) {
      console.log("Error****:", error.message);
    });
  }

  Substring_name(pe_clientName){
    if(pe_clientName == undefined){
      return '';
    }

    if(pe_clientName.length > 22){
      return pe_clientName.substring(0, 22) + '...';
    }

    return pe_clientName;
  }

  GestIsMultiClient(pe_authUserMulti){

    if(pe_authUserMulti == null){
      return false;
    }

    if(pe_authUserMulti == undefined){
      return false;
    }

    if(pe_authUserMulti.ListClient == undefined){
      return false;
    }

    if(pe_authUserMulti.ListClient.length > 0){
      childrenClient.splice(0,childrenClient.length)
       for (let i = 0; i < pe_authUserMulti.ListClient.length; i++) {
         childrenClient.push(<Option value={pe_authUserMulti.ListClient[i].IDClient}>{ pe_authUserMulti.ListClient[i].Nom }</Option>);
       }
      return true;
    }

    return false;

  }

  RenderImg(pe_authUser){

    if(pe_authUser){
      if(pe_authUser.ImageProfil == ''){
        return require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)
      }else{
        return pe_authUser ? static_url+ pe_authUser.ImageProfil : require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)
      }

    }else{
      return require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)
    }
  }

  render() {
    const {authUser} = this.props;

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Se déconnecter
        </li>
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src= {this.RenderImg(authUser)}
                  className="gx-size-30 gx-pointer gx-mr-2" alt=""/>

                <span className="gx-avatar-name ">
                  {authUser ? authUser.Nom + ' ' + authUser.Prenom : "Chargement"}
                  <i className="icon icon-chevron-down gx-fs-xxxs gx-ml-1"/>
                </span>
        </Popover>
        {
          this.GestIsMultiClient(authUser) &&
          <Select
              showSearch
              style={{width: 650, marginTop: 20}}
              placeholder="Liste societé"
              optionFilterProp="childrenClient"
              ref="Sim"
              onChange={this.handleChangeClient}
              defaultValue={authUser ? this.Substring_name(authUser.NomClient) : ""}
              value={authUser ? this.Substring_name(authUser.NomClient) : ""}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {childrenClient}
            </Select>
        }

      </div>

    )
  }
}
// value={this.state.IDSim}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignOut, userChangerMutliClient} )(UserProfile);
