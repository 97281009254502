import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";

import SidebarLogo from "./SidebarLogo";
import SidebarLogoBottom from "./SidebarLogoBottom";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

// Menu client
import COMPANY_ADMIN from "./ROLES/COMPANY_ADMIN";
import COMPANY_COMPTA from "./ROLES/COMPANY_COMPTA";
import COMPANY_BUYER from "./ROLES/COMPANY_BUYER";

// Menu YDEO Staff
import YDEO_ADV from "./ROLES/YDEO_ADV";
import YDEO_QUALITE from "./ROLES/YDEO_QUALITE";
import YDEO_COMPTA from "./ROLES/YDEO_COMPTA";
import YDEO_MARKETING from "./ROLES/YDEO_MARKETING";

class SidebarContent extends Component {

  state = {
    IDCompte:null
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  GetMenu = (scopeLib, defaultOpenKeys, selectedKeys, themeType, IsAccesSuiviTechnique, IsChiffreMasque ) =>{

    if(scopeLib === undefined){
      return <COMPANY_ADMIN defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} IsAccesSuiviTechnique={IsAccesSuiviTechnique} IsChiffreMasque={IsChiffreMasque}/>
    }else if(scopeLib == 'COMPANY_ADMIN'){
      return <COMPANY_ADMIN defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} IsAccesSuiviTechnique={IsAccesSuiviTechnique} IsChiffreMasque={IsChiffreMasque}/>
    }else if(scopeLib == 'COMPANY_COMPTA'){
      return <COMPANY_COMPTA defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} IsAccesSuiviTechnique={IsAccesSuiviTechnique} IsChiffreMasque={IsChiffreMasque}/>
    }else if(scopeLib == 'COMPANY_BUYER'){
      return <COMPANY_BUYER defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} IsAccesSuiviTechnique={IsAccesSuiviTechnique} IsChiffreMasque={IsChiffreMasque}/>
    }else{
      return <COMPANY_ADMIN defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType} IsAccesSuiviTechnique={IsAccesSuiviTechnique} IsChiffreMasque={IsChiffreMasque}/>
    }
  }

  GetMenuAtlas = (scopeLib, defaultOpenKeys, selectedKeys, themeType) =>{

    if(scopeLib === undefined){
      return <YDEO_ADV defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>
    }else if (scopeLib == 'YDEO_QUALITE'){
      return <YDEO_QUALITE defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>
    }else if (scopeLib == 'YDEO_MARKETING'){
      return <YDEO_MARKETING defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>
    }else if (scopeLib == 'YDEO_MARKETING'){
      return <YDEO_COMPTA defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>
    }else{
      return <YDEO_ADV defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>
    }
  }

  componentDidMount(){

    const splitedUrl  = window.location.href.split('/')
    const IDCompte    = splitedUrl[splitedUrl.length - 1]
    if(parseInt(IDCompte)>0){
      console.log(IDCompte)
      this.setState({
        IDCompte:IDCompte
      })
    }
  }

  render() {

    //console.log(this.props);

    const {themeType, navStyle, pathname, IDEmploye, IDCompteAtlas, IDClientStaffSession, scopeLib, IsAccesSuiviTechnique, IsChiffreMasque} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    console.log('IsChiffreMasque !!!!!!!!!!->' + IsChiffreMasque)
    //CHECK IF YDEO STAFF OR NOT
    if(IDCompteAtlas){
      return (
        <Auxiliary>
          <SidebarLogo/>
          <div className="gx-sidebar-content">
            <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
              <UserProfile/>
              <AppsNavigation/>
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline">
                {this.GetMenuAtlas(scopeLib, defaultOpenKeys, selectedKeys, themeType)}
              </Menu>
            </CustomScrollbars>
          </div>
        </Auxiliary>
      );
    }
    //IF NOT YDEO STAFF SET CLASSIC NAV
    else{
      return (
        <Auxiliary>
          <SidebarLogo/>
          <div className="gx-sidebar-content">
            <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
              <UserProfile/>
              <AppsNavigation/>
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline">
                {this.GetMenu(scopeLib, defaultOpenKeys, selectedKeys, themeType, IsAccesSuiviTechnique, IsChiffreMasque)}
              </Menu>
            </CustomScrollbars>
          </div>
        </Auxiliary>
      );
    }

  }
}



/*

return (
  <Auxiliary>
    <SidebarLogo/>
    <div className="gx-sidebar-content">
      <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
        <UserProfile/>
        <AppsNavigation/>
      </div>
      <CustomScrollbars className="gx-layout-sider-scrollbar">
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode="inline">


        </Menu>
      </CustomScrollbars>
    </div>
  </Auxiliary>
);



*/

//   //<COMPANY_ADMIN defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} themeType={themeType}/>



/*
<CustomScrollbars className="gx-layout-sider-scrollbar">
  <Menu
    defaultOpenKeys={[defaultOpenKeys]}
    selectedKeys={[selectedKeys]}
    theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
    mode="inline">
    <Menu.Item key="dashboard">
      <Link to="/dashboard"><i className="icon icon-widgets"/>
        <IntlMessages id="sidebar.dashboard"/></Link>
    </Menu.Item>

    <Menu.Item key="commande">
      <Link to="/commande"><i className="icon icon-shopping-cart"/>
        <IntlMessages id="sidebar.commandes"/></Link>
    </Menu.Item>

    <Menu.Item key="bon_livraison">
      <Link to="/bon_livraison"><i className="icon icon-attachment"/>
        <IntlMessages id="sidebar.BL"/></Link>
    </Menu.Item>

    <Menu.Item key="facture">
      <Link to="facture"><i className="icon icon-orders"/>
        <IntlMessages id="sidebar.Factures"/></Link>
    </Menu.Item>

    <Menu.Item key="adresses">
      <Link to="/adresses"><i className="icon icon-map-google"/>
        <IntlMessages id="sidebar.Adresses"/></Link>
    </Menu.Item>

    <Menu.Item key="moncompte">
      <Link to="/moncompte"><i className="icon icon-user"/>
        <IntlMessages id="sidebar.InfoPerso"/></Link>
    </Menu.Item>

    <Menu.Item key="administration">
      <Link to="/administration"><i className="icon icon-setting"/>
        <IntlMessages id="sidebar.Admin"/></Link>
    </Menu.Item>




  </Menu>
</CustomScrollbars>
*/
SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {IDCompteAtlas, IDEmploye, scopeLib, IsAccesSuiviTechnique, IsChiffreMasque} = auth
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname, IDEmploye, IDCompteAtlas, scopeLib, IsAccesSuiviTechnique, IsChiffreMasque}
};
export default connect(mapStateToProps)(SidebarContent);
