import React from "react";
import {Button, Form, Input} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {exchangeToken} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

class RefreshToken extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      _reCaptchaRef:null
    };
  }


  async componentDidMount(){
    const refresh_token = await localStorage.getItem('refresh_token')
    this.props.exchangeToken(refresh_token)
  }



  render() {
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {exchangeToken})(RefreshToken)
