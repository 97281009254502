import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {userSignUp,checkGUID, userSignIn} from "../appRedux/actions/Auth";
import {message} from 'antd';
import { hasUpperCase2, hasSpecialCaracters, hasNumber } from 'util/Toolbox'

import {getAPI_KEY_CAPTCHA} from "util/API_Utilities";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      isPending : true,
      password : '',
      passwordConfirm : '',
      SITE_KEY : '',
      user : {Email:""},
      GUID:"",
      DELAY : 1000,
      _reCaptchaRef : '',
      loading: false,
      isHidenPassword : false,
      hidenPassword : 'password',

    }
  }

  onChangePassword = (e) => {

    let hidenPassword = '';
    if(e.target.checked == true){
      hidenPassword = '';
    }else{
      hidenPassword = 'password';
    }

    this.setState({isHidenPassword : e.target.checked, hidenPassword : hidenPassword});
  }

  handleCaptchaChange = value => {

    this.setState({_reCaptchaRef: value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };


  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    //console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };


  componentDidUpdate() {
    this.state.SITE_KEY =  getAPI_KEY_CAPTCHA();

    setTimeout(() => {
      this.setState({ load: true });
    }, this.state.DELAY);

  }


  GetURLImgParam(){

    // Suivant un paramètre dans l'URL on charge une image diffétente (exemple LBE)
    switch (this.state.param) {
      case 'lbe':
        return require(`assets/images/IMG_PARAM/logo_lbe.png`);
        break;
      default:
        return require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`);
    }
  }


  renderInfoPassword(pe_tab){
    return (
      <div>
        <div>
            {pe_tab}
        </div>
      </div>
    );
  }


  GenerateMessage(pe_text) {

    let tabMessage     = [];

    tabMessage.push(<p>Votre mot de passe doit contenir: </p>);

    if(pe_text.length < 10){
      tabMessage.push(<p>- au moins 10 caractères </p>);
    }else{
      tabMessage.push(<p style={{ color: 'green'}}>- au moins 10 caractères </p>);
    }

    if(! hasUpperCase2(pe_text)){
      tabMessage.push(<p>- 1 Majuscule </p>);
    }else{
      tabMessage.push(<p style={{ color: 'green'}}>- 1 Majuscule </p>);
    }

    if(! hasNumber(pe_text) ){
      tabMessage.push(<p>- 1 chiffre</p>);
    }else{
      tabMessage.push(<p style={{ color: 'green'}}>- 1 chiffre </p>);
    }

    if(! hasSpecialCaracters(pe_text) ){
      tabMessage.push(<p>- 1 caractère spécial (*!+$)</p>);
    }else{
      tabMessage.push(<p style={{ color: 'green'}}>- 1 caractère spécial (*!+$) </p>);
    }

    return tabMessage;

  }


  renderMessageErrorPassword = () => {

    let tabMessage   = [];

    tabMessage = this.GenerateMessage(this.state.password);

    return this.renderInfoPassword(tabMessage);
  }


  renderMessageErrorPasswordConfirm = () => {

    let tabMessage   = [];

    tabMessage = this.GenerateMessage(this.state.passwordConfirm);

    return this.renderInfoPassword(tabMessage);

  }


  handleSubmit = (e) => {


    e.preventDefault();
/*
    const form = this.props.form;
    if (form.getFieldValue('password') === undefined) {
      this.SendMessageAvertissement('Entrer un mot de passe valide.');
      return;
    }
*/

    this.props.form.validateFields((err, values) => {

      var isOK = true;

      if(values.Password === ''){
        this.SendMessageError('Entrer un mot de passe valide.');
        return;
      }
      // Control si mot de passe identique
      if(values.Password === values.Confirm_Password){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être identique !!!!')
        this.SendMessageError('Les mots de passes doivent être identique !');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length >= 10){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être supérieurs à 10 caractères !!!!')
        this.SendMessageError('Les mots de passes doivent être supérieurs à 10 caractères !');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length <= 30){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être inférieurs à 30 caractères !!!!')
        this.SendMessageError('Les mots de passes doivent être inférieurs à 30 caractères !');
        return;
      }

      const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+=/.@$%^&*)-]).{10,}$`);

      if(!values.Password.match(strongRegex) ) {
        isOK = false
        console.log('Entrer votre mot de passe: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial !!!!')
        this.SendMessageError('Votre mot de passe doit contenir: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial');
        return;
      }

      if(this.state._reCaptchaRef == ''){
        this.SendMessageAvertissement('Cliquer sur "Je ne suis pas un robot" pour créér votre compte.');
        return;
      }

      if(isOK){
        values.GUID = this.state.GUID
        if (!err) {
          values.Email = this.state.user.Email;
          values.captcha_token = this.state._reCaptchaRef
          this.setState({loading: true});
          setTimeout(() => {
              this.setState({loading: false});
          }, 4000);
          this.props.userSignUp(values);
          setTimeout(() => {
            this.props.userSignIn({email:values.Email, password:values.Password, captcha_token:values.captcha_token, isGoHomePage : true});
          }, 800);
        }
      }

    });
  };


  SendMessageError(pe_message){
    message.error(pe_message);
  }


  SendMessageAvertissement(pe_message){
    message.warning(pe_message);
  }


  //RECUPERATION DU GUID AVANT MOTNAGE DU COMPOSANT
  async componentDidMount() {
    const location = window.location.href
    const path = location.split('/');
    const GUID = path[path.length - 1]
    checkGUID(GUID)
    .then((user)=>{
      this.setState({ isValid:true ,isPending:false , GUID:GUID , user:user.compte})
    }).catch(error=>{
      window.location.replace("/")
      this.setState({ isValid:false ,isPending:false})
      console.log(error)
    });
  }


  strongValidatorPassword = (rule, value, callback) => {

    const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+=/.@$%^&*)-]).{10,}$`);
    this.state.password = value;
    if(!value.match(strongRegex) ) {
      return callback(true)
    }
    callback()
}


  strongValidatorPasswordConfirm = (rule, value, callback) => {

    const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+=/.@$%^&*)-]).{10,}$`);
    this.state.passwordConfirm = value;
    if(!value.match(strongRegex) ) {
      return callback(true)
    }
    callback()
  }



  render() {
    const {getFieldDecorator} = this.props.form;

    if(this.state.isPending){

      return (
        <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <InfoView/>
            </div>
          </div>
        </div>
      );


    }else if(this.state.isValid){
      return (
        <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  <img src='https://via.placeholder.com/272x395' alt='Neature'/>
                </div>
                <div className="gx-app-logo-wid">
                <div className="gx-app-logo">
                  <img style = {{marginBottom : 10}} src={this.GetURLImgParam()}/>
                </div>
                  <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                  <p><IntlMessages id="app.userAuth.bySignUp"/></p>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-shopping-cart"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Commandes</div>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-attachment"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Bons livraison</div>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-orders"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Factures</div>
                </div>
                <div className="gx-app-logo">
                  <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/ydeo_connect.png`)}/>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                  <FormItem>

                    {getFieldDecorator('Email', {
                      rules: [{
                         type: 'email', message: 'Cet email n est pas valide!',
                      }],
                    })(

                      <Input disabled="disabled" placeholder={this.state.user ? this.state.user.Email : "Loading"}/>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('Password', {
                      rules: [{required: true, message: this.renderMessageErrorPassword, validator: this.strongValidatorPassword}],
                    })(
                      <Input type={this.state.hidenPassword}  placeholder="Entrer votre mot de passe"/>
                    )}
                  </FormItem>

                  <FormItem>
                    {getFieldDecorator('Confirm_Password', {
                      rules: [{required: true, message: this.renderMessageErrorPasswordConfirm, validator: this.strongValidatorPasswordConfirm},
                    ],
                    })(
                      <Input type={this.state.hidenPassword}  placeholder="Entrer une deuxième fois votre mot de passe"/>
                    )}
                  </FormItem>

                  <Checkbox onChange={this.onChangePassword}>Afficher votre mot de passe</Checkbox>


                  {this.state.load && (
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="white"
                        ref={this.state._reCaptchaRef}
                        sitekey={this.state.SITE_KEY}
                        onChange={this.handleCaptchaChange}
                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                      />
                    )}

                  <FormItem>
                    <Button type="primary" loading={this.state.loading} className="gx-mb-0" htmlType="submit">
                      <IntlMessages id="app.userAuth.signUp"/>
                    </Button>
                  </FormItem>
                </Form>
              </div>
              <InfoView/>
            </div>
          </div>
        </div>
      );
    }
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignUp, userSignIn})(WrappedSignUpForm);
