import React from "react";
import {Button, Form, Input, Card, Select} from "antd";
import {getSalon} from "util/API_Utilities";
import {connect} from "react-redux";

import {newPassword} from "../appRedux/actions/Auth";

import {message} from 'antd';
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

import axios from 'util/Api'

const FormItem = Form.Item;


class SignUp_formSalons extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      GUID:'',
    }
  }


  strongValidator = (rule, value, callback) => {
    const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$`);
    if(!value.match(strongRegex) ) {
      return callback(true)
    }
    callback()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("values", values);

      try {


        /*
        axios.post('/auth/subscriptionLBE',values
          ).then(({data}) => {
            console.log(data)

          //  message.success(`Article modifié avec succès`);
          //  this.Actualiser();
          message.success(`Inscription validée avec succès, nous vous re-contactons rapidement`);
          }).catch(function (error) {
            console.log("Error****:", error.message);
            message.error(`Une erreur c'est produite lors de l'inscription`);
          });
          */
          console.log(values);


      } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
      }


    });
  };

  SendMessageError(pe_message){
    message.error(pe_message);
  }


  render() {
    const {getFieldDecorator} = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };


    /*
    onChange={this.handleChange.bind(this, "DureeCreneau")}
    */
    return (

      <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
        <div className="gx-app-login-container">

      <Card className="gx-card" title="Demande inscription La belle époque">

      <Form onSubmit={this.handleSubmit}>

      <FormItem
        {...formItemLayout}
        label="Salon"
      >
        {getFieldDecorator('Salon', {
          rules: [{
            required: true, message: 'Entrer votre prénom',
          }],
          initialValue : ""
        })(
          <Select
                        showSearch
                        style={{width: '100%'}}
                        placeholder="Salon"

                      >
                        {getSalon()}
                      </Select>

        )}
      </FormItem>


      <FormItem
        {...formItemLayout}
        label="Prénom"
      >
        {getFieldDecorator('Prenom', {
          rules: [{
            required: true, message: 'Entrer votre prénom',
          }],
          initialValue : ""
        })(
          <Input id="prenom" placeholder={""}/>
        )}
      </FormItem>
      <FormItem
        {...formItemLayout}
        label="Nom"
      >
        {getFieldDecorator('Nom', {
          rules: [{
            required: true, message: 'Entrer votre nom',
          }],
          initialValue : ""
        })(
          <Input id="nom" placeholder={""}/>
        )}
      </FormItem>
        <FormItem
          {...formItemLayout}
          label="E-mail"
        >
          {getFieldDecorator('Email', {
            rules: [{
              type: 'email', message: `Cette adresse email n'est pas valide`,
            }, {
              required: true, message: `Cette adresse email n'est pas valide`,
            }],
            initialValue : ""
          })(
            <Input id="email1" placeholder={""}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Raison sociale"
        >
          {getFieldDecorator('RaisonSociale', {
            rules: [{
              required: true, message: 'Entrer votre raison sociale',
            }],
            initialValue : ""
          })(
            <Input id="raisonSociale" placeholder={""}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Adresse"
        >
          {getFieldDecorator('Adresse', {
            rules: [{
              required: true, message: 'Entrer votre adresse',
            }],
            initialValue : ""
          })(
            <Input id="adresse" placeholder={""}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Code postal"
        >
          {getFieldDecorator('CodePostal', {
            rules: [{
              required: true, message: 'Entrer votre code postal',
            }],
            initialValue : ""
          })(
            <Input id="codePostal" placeholder={""}/>
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Ville"
        >
          {getFieldDecorator('Ville', {
            rules: [{
              required: true, message: 'Entrer votre ville',
            }],
            initialValue : ""
          })(
            <Input id="ville" placeholder={""}/>
          )}
        </FormItem>


        <FormItem
          {...formItemLayout}
          label="Téléphone"
        >
          {getFieldDecorator('Telephone', {
            rules: [{required: true, message: 'Enter votre numéro de téléphone'}],
            initialValue: ""
          })(
            <Input id="telephone" style={{width: '100%'}} placeholder={""}
            />
          )}
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="SIRET"
        >
          {getFieldDecorator('Siret', {
            rules: [{required: true, message: 'Enter votre numéro de téléphone'}],
            initialValue: ""
          })(
            <Input id="siret"  style={{width: '100%'}} placeholder={""}
            />
          )}
        </FormItem>




        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">Enregistrer</Button>
        </FormItem>
      </Form>

      </Card>

      </div>
      </div>





    );

  }
}


/*


<div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
  <div className="gx-app-login-container">
    <div className="gx-app-login-main-content">
      <div className="gx-app-logo-content">
        <div className="gx-app-logo-content-bg">
            <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
        </div>
        <div className="gx-app-logo-wid">
          <h1><IntlMessages id="app.userAuth.signUp.titreLBE"/></h1>
          <p><IntlMessages id="app.userAuth.signUp.descriptionLBE"/></p>
        </div>
        <div className="gx-app-logo">
          <img alt="example" src={require(`assets/images/IMG_PARAM/logo_lbe.png`)}/>
        </div>
      </div>

      <div className="gx-app-login-content">



        <Form onSubmit={this.handleSubmit}>
        <FormItem
          {...formItemLayout}
          label="Prénom"
        >
          {getFieldDecorator('Prenom', {
            rules: [{
              required: true, message: 'Entrer votre prénom',
            }],
            initialValue : ""
          })(
            <Input id="prenom" placeholder={""}/>
          )}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Nom"
        >
          {getFieldDecorator('Nom', {
            rules: [{
              required: true, message: 'Entrer votre nom',
            }],
            initialValue : ""
          })(
            <Input id="nom" placeholder={""}/>
          )}
        </FormItem>
          <FormItem
            {...formItemLayout}
            label="E-mail"
          >
            {getFieldDecorator('Email', {
              rules: [{
                type: 'email', message: `Cette adresse email n'est pas valide`,
              }, {
                required: true, message: `Cette adresse email n'est pas valide`,
              }],
              initialValue : ""
            })(
              <Input id="email1" disabled={true} placeholder={""}/>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Raison sociale"
          >
            {getFieldDecorator('RaisonSociale', {
              rules: [{
                required: true, message: 'Entrer votre nom',
              }],
              initialValue : ""
            })(
              <Input id="raisonSociale" placeholder={""}/>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Adresse"
          >
            {getFieldDecorator('Adresse', {
              rules: [{
                required: true, message: 'Entrer votre nom',
              }],
              initialValue : ""
            })(
              <Input id="adresse" placeholder={""}/>
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Code postal"
          >
            {getFieldDecorator('CodePostal', {
              rules: [{
                required: true, message: 'Entrer votre nom',
              }],
              initialValue : ""
            })(
              <Input id="co" placeholder={""}/>
            )}
          </FormItem>


          <FormItem
            {...formItemLayout}
            label="Téléphone fixe"
          >
            {getFieldDecorator('Telephone', {
              rules: [{required: false, message: 'Entrer votre numéro de téléphone'}],
              initialValue : ""
            })(
              <Input  style={{width: '100%'}} placeholder={""}
              />
            )}
          </FormItem>

          <FormItem
            {...formItemLayout}
            label="Fax"
          >
            {getFieldDecorator('Fax', {
              rules: [{required: false, message: 'Entrer votre numéro de fax'}],
              initialValue: ""
            })(
              <Input  style={{width: '100%' }} placeholder={""}
              />
            )}
          </FormItem>


          <FormItem
            {...formItemLayout}
            label="Portable"
          >
            {getFieldDecorator('Portable', {
              rules: [{required: false, message: 'Enter votre numéro de portable'}],
              initialValue: ""
            })(
              <Input  style={{width: '100%'}} placeholder={""}
              />
            )}
          </FormItem>


          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">Enregistrer</Button>
          </FormItem>
        </Form>


      </div>
      <InfoView/>
    </div>
  </div>
</div>


*/

















/*
<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">

<FormItem>
  {getFieldDecorator('Password', {
    rules: [{required: true, message: 'Entrer votre mot de passe: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial (*!+$)', validator: this.strongValidator}],
  })(
    <Input type="password" placeholder="Mot de passe"/>
  )}
</FormItem>

<FormItem>
  {getFieldDecorator('Password_confirm', {
    rules: [{required: true, message: 'Re-rentrer votre mot de passe: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial (*!+$)', validator: this.strongValidator},
  ],
  })(
    <Input type="password" placeholder="Mot de passe"/>
  )}
</FormItem>

<FormItem>
  <Button type="primary" className="gx-mb-0" htmlType="submit">
    <IntlMessages id="app.userAuth.ChangePassword.action"/>
  </Button>
</FormItem>

</Form>
*/

const WrappedNewPasswordForm = Form.create()(SignUp_formSalons);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {newPassword})(WrappedNewPasswordForm);
