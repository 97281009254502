import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_INVOICE_DATA,
  USER_ADDRESS_DATA,
  USER_DELIVERYNOTE_DATA,
  USER_ORDERS_DATA,
  USER_DASHBOARD_DATA,
  USER_DASHBOARD_STAFF_DATA,
  USER_ADMINISTRATION_DATA,
  USER_REGISTERING_SET,
  USER_ORDERS_DATA_CLEAN,
  USER_ORDERSLINES_DATA,
  USER_ORDERSLINES_DATA_CLEAN,
  USER_DATA_CLEAN,
  USER_ITEMS_DATA,
  USER_ADMINISTRATION_DATA_CLEAN,
  USER_INVOICE_DATA_CLEAN,
  USER_TYPEDOC_DATA,
  CLEAR_ONLY_DATA,
  USER_TYPEDOC_FDS_DATA,
  USER_TYPEDOC_FT_DATA,
  USER_TYPEDOC_FT_DATA_CLEAN,
  USER_TYPEDOC_FDS_DATA_CLEAN,
  USER_TYPEDOC_OFFICIEL_DATA_CLEAN,
  USER_TYPEDOC_OFFICIEL_DATA,
  ITEMS_DATA_CLEAN,
  USER_TYPEDOC_RSE_DATA_CLEAN,
  USER_TYPEDOC_RSE_DATA,
  USER_TYPEDOC_PLAQUETTE_DATA_CLEAN,
  USER_TYPEDOC_PLAQUETTE_DATA,
  USER_TYPEDOC_FORMATION_DATA_CLEAN,
  USER_TYPEDOC_FORMATION_DATA,
  USER_TYPEDOC_DOCUMENTATION_DATA,
  USER_TYPEDOC_DOCUMENTATION_DATA_CLEAN


} from "../../constants/ActionTypes";

import axios from 'util/Api'
import {message} from "antd";

function RequestCONTROLLER(type, data, dispatch){
  if(type == 'ERROR'){
    if(data.config){
      //console.log(data.config.url)
      //console.log(data.config.method)
      switch(data.request.status){
        case 0:
          console.log('NO CONNECTION')
          break
        case 400:
          console.log('BAD REQUEST')
          break
        case 401:
          //exchangeToken(dispatch)
          localStorage.removeItem("token");
          console.log('AUTHENTIFICATION REQUIRED')
          window.location.replace("/refresh-session");
          //window.location.reload()
          break
        case 403:
          console.log('NOT AUTHORIZED')
        case 500:
          console.log('INTERNAL_ERROR')
          break
        case 502:
          console.log('SERVER_DOWN')
          break

        default:
          console.log('no event')
      }
    }else{
      console.log('Type Error')
      //console.log(data)
    }

  }
}

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

//*****************************************************************
//-------------- ------ -- REGISTERING PART  -------------- ------ --
//*****************************************************************

export const checkGUID = async (GUID) => {
  return new Promise((resolve,reject)=>{
    axios.post('auth/check', {
        GUID: GUID
      }
    ).then(({data}) => {

      if (data.success) {
        resolve(data)
      } else {
        window.location.replace("/")
        reject(data.error)
      }
    }).catch(function (error) {
      reject(error)
    });
  })
};


export const checkGUID2 = (GUID) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/check', {
        GUID: GUID
      }
    ).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_REGISTERING_SET, payload: data.compte});
      } else {
        window.location.replace("/")
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const userSignUp = ({GUID, Password, Password_Confirm, captcha_token, Email}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        GUID:GUID,
        Password: Password,
        Password_Confirm:Password_Confirm
      }
    ).then(({data}) => {

      if (data.success) {

        dispatch({type: FETCH_SUCCESS, payload: data.success});
        dispatch({type: USER_REGISTERING_SET, payload: data.success});

        // on essaye de se logger avec ses infos si reotur API OK
    //  console.log("Connexion avec:" + Password + " Captcha->" + captcha_token + ' Email->' + Email);
      //userSignIn({Email, Password, captcha_token})
        //window.location.replace("/")

      } else {
      //  console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
  //    console.log("Error****:", error.message);

    });
  }
};


export const exchangeToken = (refresh_token) => {
  return (dispatch)=>{
    if(refresh_token){
      axios.post('refresh', {refresh_token:refresh_token}
      ).then(({data}) => {
        if (data.success) {
        //  console.log('token exchanged')
          localStorage.setItem("token", data.token);
        //  console.log(data)
          //axios.defaults.headers.common['secret-token'] = "Bearer " + data.token;
          dispatch({type: FETCH_SUCCESS, payload: data.success});
          dispatch({type: USER_TOKEN_SET, payload: {token: data.token}});

          window.location.replace("/");
          //dispatch({type: FETCH_SUCCESS, payload: data.success});
          //dispatch({type: USER_TOKEN_SET, payload: data.token});
        }
      }).catch(function (error, data) {
        console.log(error)
        dispatch({type: FETCH_ERROR, payload: error.message});
        //dispatch({type: FETCH_ERROR, payload: data.errors});
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.replace("/");
      });
    }else{
      dispatch({type: FETCH_ERROR, payload: "No session found"});
      console.log('no token')
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("staff_member");
      window.location.replace("/");
    }
  }
}

//*****************************************************************
//-------------- ------ -- LOGIN / LOGOUT PART  -------------- ------ --
//*****************************************************************
export const userSignIn = ({email, password, captcha_token, isGoHomePage}) => {
  return (dispatch) => {

    console.log('userSignIn');
    console.log(email, password, captcha_token);

    dispatch({type: FETCH_START});
    axios.post('auth/login', {
        username: email,
        password: password,
        captcha_token: captcha_token
      }
    ).then(({data}) => {

     console.log(data)

    // CREDENTIAL_ERROR
      if (data.success) {

        console.log(data);
        console.log('IDCompteAtlas->' + data.IDCompteAtlas);
        if(data.IDEmploye){
            localStorage.setItem("staff_member", data.IDEmploye);
        }
        if(data.IDCompteAtlas){
            localStorage.setItem("IDCompteAtlas", data.IDCompteAtlas);
        }

        localStorage.setItem("token", data.token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("scopeLib", data.scopeLib);
        localStorage.setItem("IsAccesSuiviTechnique", data.IsAccesSuiviTechnique);
        localStorage.setItem("IsChiffreMasque", data.IsChiffreMasque);

        dispatch({type: FETCH_SUCCESS, payload: data.success});
        dispatch({type: USER_TOKEN_SET, payload: { token:data.token, IDEmploye:data.IDEmploye, scopeLib:data.scopeLib, IDCompteAtlas:data.IDCompteAtlas, IsAccesSuiviTechnique : data.IsAccesSuiviTechnique, IsChiffreMasque : data.IsChiffreMasque} });

        if(isGoHomePage){
          window.location.replace("/")
        }

      } else {
        dispatch({type: FETCH_ERROR, payload: data.errors});
      }
    }).catch(function (error,data) {

      var rep = error.response;

      var err = '';

      if(rep === undefined){
        err = 'NETWORK_ERROR';
      }else{
        err = rep.data.errors;
        if(err === undefined || err === '' || JSON.stringify(err) === '{}'){
          err = 'CREDENTIAL_ERROR'
        }
      }
      dispatch({type: FETCH_ERROR, payload: err});
    });
  }
};


export const userChangerMutliClient = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

  //  localStorage.clear();

    dispatch({
      type: CLEAR_ONLY_DATA,
      payload: [],
      offset:0
    });

    getUser();


/*
    axios.post('auth/logout').then(({data}) => {

      if (data.success) {
        setTimeout(() => {

          localStorage.clear();

          if('caches' in window){
          caches.keys().then((names) => {
                  // Delete all the cache files
                  names.forEach(name => {
                      console.log(name)
                      caches.delete(name);
                  })
              });
              // Makes sure the page reloads. Changes are only visible after you refresh.
              window.location.reload(true);
          }

          dispatch({type: FETCH_SUCCESS , payload: data.success});
          dispatch({type: SIGNOUT_USER_SUCCESS});
        }, 500);
      } else {
      //  console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});

      }
    })

*/

  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    localStorage.clear();

    dispatch({
      type: USER_DATA_CLEAN,
      payload: [],
      offset:0
    });


    axios.post('auth/logout').then(({data}) => {

      if (data.success) {
        setTimeout(() => {

          localStorage.clear();

          if('caches' in window){
          caches.keys().then((names) => {
                  // Delete all the cache files
                  names.forEach(name => {
                      console.log(name)
                      caches.delete(name);
                  })
              });
              // Makes sure the page reloads. Changes are only visible after you refresh.
              window.location.reload(true);
          }

          dispatch({type: FETCH_SUCCESS , payload: data.success});
          dispatch({type: SIGNOUT_USER_SUCCESS});
        }, 500);
      } else {
      //  console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});

      }
    })
  }
};


export const resetPassword = ({Email}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/reset-password',{
        Email: Email,
    }).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


export const newPassword = ({Password, Password_confirm, GUID}) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/reset-password/confirm',{
        Password: Password,
        Password_confirm: Password_confirm,
        GUID :GUID,
    }).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        window.location.replace("/")
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


//*********************************************************************************
//---------------------------------------------------------------------------------
//-------------- ------ --  END AUTH PART  -------------- ------ -----------------
//---------------------------------------------------------------------------------
//*********************************************************************************


//*****************************************************************
//-------------- ------ --  USER PART  -------------- ------ --
//*****************************************************************

export const getUser = (id_compte = '') => {


  //console.log('getUser !!!!!!!!->' + id_compte)
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('profile/me',
    ).then(({data}) => {

      if (data.success) {
        //console.log(data.user);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};


export const updateUser = ({Nom,Prenom, Email, Portable,Fax,Telephone,IsNewletter,IsReceptionOffreSpeciales, ListNotif}) => {

  console.log(Nom,Prenom,Portable,Fax,Telephone,IsNewletter,IsReceptionOffreSpeciales, ListNotif);

  return (dispatch) => {

    dispatch({type: FETCH_START});

    axios.post('profile/me', {
        Nom:Nom,
        Prenom:Prenom,
        Portable:Portable,
        Fax:Fax,
        Email :Email,
        Telephone:Telephone,
        IsNewletter : IsNewletter ? 1 : 0,
        IsReceptionOffreSpeciales : IsReceptionOffreSpeciales ? 1 : 0,
        ListNotif: ListNotif
      }
    ).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        message.success('Modification réalisée avec succès');
        window.location.replace("/moncompte")
      } else {
        message.error('Erreur lors de la modification');
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};


//*****************************************************************
//-------------- ------ --  INVOICE PART  -------------- ------ --
//*****************************************************************
export const getInvoice = (offset,quantity, pe_IDStatutFacture, id_compte = null) => {
  return (dispatch) => {
    var idStatutFacture = 0;

    if(pe_IDStatutFacture === undefined){
      idStatutFacture = 0;
    }else{
      idStatutFacture = pe_IDStatutFacture
      if(offset === 0){
        dispatch({
          type: USER_INVOICE_DATA_CLEAN,
          payload: [],
          offset:offset + quantity
        });
      }
    }






    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/${offset}/${quantity}/${idStatutFacture}/invoice?id_compte=${id_compte}` : `profile/${offset}/${quantity}/${idStatutFacture}/invoice`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        dispatch({
          type: USER_INVOICE_DATA,
          payload: data.invoice_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


export const searchInvoice = (offset, invoice_code, id_compte = null) => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/search/invoice/${invoice_code}?id_compte=${id_compte}` : `profile/search/invoice/${invoice_code}`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        dispatch({
          type: USER_INVOICE_DATA,
          payload: data.invoice_LIST,
          offset:offset
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ --  ADDRESS PART  -------------- ------ --
//*****************************************************************
export const getAddress = (offset,quantity, id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/${offset}/${quantity}/address?id_compte=${id_compte}` : `profile/${offset}/${quantity}/address`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ADDRESS_DATA,
          payload: data.address_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ --  ORDERS PART  -------------- ------ --
//*****************************************************************
export const getOrders = (offset,quantity,pe_IDStatutCommande = 'undefined', id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    var IDStatutCommande = 0;

    if(pe_IDStatutCommande === undefined){
      IDStatutCommande = 0;
    }else{
      IDStatutCommande = pe_IDStatutCommande
      if(offset === 0){
        dispatch({
          type: USER_ORDERS_DATA_CLEAN,
          payload: [],
          offset:offset + quantity
        });
      }
    }

    console.log('id_compte')
    console.log(id_compte)

    const url = id_compte ? `profile/${offset}/${quantity}/${IDStatutCommande}/orders?id_compte=${id_compte}` : `profile/${offset}/${quantity}/${IDStatutCommande}/orders`
    console.log(url);
    axios.get(url).then(({data}) => {
      //console.log(data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ORDERS_DATA,
          payload: data.orders_LIST,
          offset:offset + quantity,
          ordersInfo : data.ordersInfo
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


export const getOrdersLines = (codeCommandeClient, id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});


    dispatch({
      type: USER_ORDERSLINES_DATA_CLEAN,
      payload: [],
    });

    const url = id_compte ? `profile/${codeCommandeClient}/orderslines?id_compte=${id_compte}` : `profile/${codeCommandeClient}/orderslines`

    axios.get(url).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ORDERSLINES_DATA,
          payload: data.orderslines_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
  //    console.log("Error****:", error.message);
    });
  }
};


export const searchOrders = (offset, order_code, pe_IDStatutCommande, id_compte = null) => {
  return (dispatch) => {

    var IDStatutCommande = 0;

    dispatch({type: FETCH_START});

    if(pe_IDStatutCommande === undefined){
      IDStatutCommande = 0;
    }else{
      IDStatutCommande = pe_IDStatutCommande
    }
    dispatch({
      type: USER_ORDERS_DATA_CLEAN,
      payload: [],
      offset:offset
    });

    const url = id_compte ? `profile/search/orders/${IDStatutCommande}/${order_code}?id_compte=${id_compte}` : `profile/search/orders/${IDStatutCommande}/${order_code}`

    axios.get(url).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ORDERS_DATA,
          payload: data.orders_LIST,
          offset:offset,
          ordersInfo : data.ordersInfo
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};


export const postOrder = (commande, id_compte = null) => {
  /*
  <CommandeClient>
	<Observation_transport>Horaires Livraison :  8h30-12h - 14h-17h30</Observation_transport>
	<Date_prevue>10/04/2020</Date_prevue>
	<nocommande>num00006</nocommande>
	<CodeClient>087</CodeClient>
	<IDAdresse_liv>1254</IDAdresse_liv>
	<IDAdresse_fac>517</IDAdresse_fac>
	<ReferenceClient>Commande CRM</ReferenceClient>
	<Lignes>
		<ligne>
			<CodeArticle>$EMB-COLIS</CodeArticle>
			<IDTarifMercury>47206</IDTarifMercury>
			<Quantite>4</Quantite>
			<NumLigne>1</NumLigne>
		</ligne>
		<ligne>
			<CodeArticle>001314102</CodeArticle>
			<IDTarifMercury>48727</IDTarifMercury>
			<Quantite>6</Quantite>
			<NumLigne>2</NumLigne>
		</ligne>
		<ligne>
			<CodeArticle>001317202</CodeArticle>
			<IDTarifMercury>48728</IDTarifMercury>
			<Quantite>8</Quantite>
			<NumLigne>3</NumLigne>
		</ligne>
		<ligne>
			<CodeArticle>001309005</CodeArticle>
			<IDTarifMercury>43147</IDTarifMercury>
			<Quantite>2</Quantite>
			<NumLigne>4</NumLigne>
		</ligne>
	</Lignes>
</CommandeClient>
  */


  return (dispatch) => {
  //  console.log('Commande  -> ' + commande);
    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/order?id_compte=${id_compte}` : `profile/order`

    axios.post(url, { commande }).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      //  window.location.replace("/moncompte")
      } else {
        //console.log(data.error)

        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ --  ADMINISTRATION PART  -------------- ------ --
//*****************************************************************
export const getAdministration = (id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({
      type: USER_ADMINISTRATION_DATA_CLEAN,
    });

    const url = id_compte ? `admin/users?id_compte=${id_compte}` : `admin/users`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ADMINISTRATION_DATA,
          payload: data.administration_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};


// ----------------------------------------------------------------------
//*****************************************************************
//-------------- ------ --  ITEMS PARTS  -------------- ------ --
//*****************************************************************

/*

export const searchClients = (offset, quantity, isAllClient, pe_textSearch) => {


  var vl_isAllClient = 0;

  if(isAllClient == undefined){
    vl_isAllClient = 0;
  }else{
    vl_isAllClient = isAllClient
  }

  console.log('Avant envoi !!!!' + offset + ' ' + quantity + ' ' +isAllClient + ' ' + pe_textSearch)

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: CLIENTS_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    axios.get(`clients/search/${offset}/${quantity}/${vl_isAllClient}/${pe_textSearch}`)
    .then(({data}) => {
      if (data.success) {
        console.log(data.clients_LIST);
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: CLIENTS_DATA,
          payload: data.clients_LIST,
          offset:offset + quantity
        });

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


*/


export const searchItems = (offset, quantity, hide_Items, pe_searchItems, id_compte = null) => {


  console.log('searchItems');
  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: ITEMS_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }


    dispatch({type: FETCH_START});
    const url = id_compte ? `items/${offset}/${quantity}/${pe_searchItems}/search?id_compte=${id_compte}` : `items/${offset}/${quantity}/${pe_searchItems}/search`

    console.log(url);

    axios.get(url).then(({data}) => {

      if (data.success) {

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ITEMS_DATA,
          payload: data.items_LIST,
          offset:offset + quantity,
          items_LIST_HIDE : hide_Items
        });

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};

export const getItems = (offset,quantity, hide_Items, id_compte = null) => {

  //console.log('getItems offset:' + offset + '  Qte: ' + quantity)
//  console.log( 'hide item -> ' + hide_Items)


  console.log('id_compte get item->' + id_compte);

  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/${offset}/${quantity}/items?id_compte=${id_compte}` : `profile/${offset}/${quantity}/items`

    axios.get(url).then(({data}) => {

      if (data.success) {
        console.log(data)

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_ITEMS_DATA,
          payload: data.items_LIST,
          offset:offset + quantity,
          items_LIST_HIDE : hide_Items
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ --  BLPART  -------------- ------ --
//*****************************************************************

export const getDeliveryNote = (offset,quantity, id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/${offset}/${quantity}/delivery-note?id_compte=${id_compte}` : `profile/${offset}/${quantity}/delivery-note`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_DELIVERYNOTE_DATA,
          payload: data.deliverynote_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


export const searchDeliveryNote = (offset, deliveryNote_code, id_compte = null) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = id_compte ? `profile/search/delivery-note/${deliveryNote_code}?id_compte=${id_compte}` : `profile/search/delivery-note/${deliveryNote_code}`

    axios.get(url).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_DELIVERYNOTE_DATA,
          payload: data.deliverynote_LIST,
          offset:offset
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
    });
  }
};


export const getDashboardStaff = () => {

  return (dispatch) => {

    dispatch({type: FETCH_START});

    const url = `profile/dashboardstaff`

    axios.get(url)
    .then(({data}) => {
      //console.log(data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_DASHBOARD_STAFF_DATA,
          payload: data.dashboard_LIST
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }

};


export const getDashboard = (id_compte = null) => {

  return (dispatch) => {

    dispatch({type: FETCH_START});

    const url = id_compte ? `profile/dashboard?id_compte=${id_compte}` : `profile/dashboard`

    //console.log(url);

    axios.get(url)
    .then(({data}) => {
        console.log(data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_DASHBOARD_DATA,
          payload: data.dashboard_LIST
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }

};


export const getDocumentFORMATION = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentFORMATION !!!! offset' + offset + 'quantity ->' + quantity + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_FORMATION_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_FORMATION_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocumentPLAQUETTE = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentPLAQUETTE !!!! offset' + offset + 'quantity ->' + quantity + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_PLAQUETTE_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_PLAQUETTE_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocumentRSE = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentRSE !!!! offset' + offset + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_RSE_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_RSE_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocumenFDS = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentFDS !!!!  id_compte->' + id_compte + ' offset-> ' + offset + ' quantity-> ' + quantity + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {
    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_FDS_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`
    console.log(url)
    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_FDS_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocumentOFFICIEL = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentOFFICIEL !!!! offset' + offset + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_OFFICIEL_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_OFFICIEL_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getAdminDocumentFT = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentFT !!!! offset' + offset + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_FT_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});

    const url = `/documentstaff/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
      console.log(data.typeDoc_LIST)
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_FT_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getAdminDocumentDOCUMENTATION = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentDocumentation !!!! offset' + offset + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_DOCUMENTATION_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});

    const url = `/documentstaff/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
      console.log(data.typeDoc_LIST)
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_DOCUMENTATION_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocumentFT = (offset, quantity, pe_ConstTypeDocument, pe_search, id_compte = null) => {

  console.log('getDocumentFT !!!! offset' + offset + ' pe_search ' + pe_search)

  if(offset == undefined){
    offset = 0;
  }

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: USER_TYPEDOC_FT_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document?id_compte=${id_compte}` : `/document/${offset}/${quantity}/${pe_ConstTypeDocument}/${pe_search}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_FT_DATA,
          payload: data.typeDoc_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};


export const getDocument = (pe_ConstTypeDocument, id_compte = null) => {

  console.log('getDocument !!!!')

  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = id_compte ? `/document/${pe_ConstTypeDocument}/document?id_compte=${id_compte}` : `/document/${pe_ConstTypeDocument}/document`

    axios.get(url)
    .then(({data}) => {
       console.log(data.typeDoc_LIST);
      if (data.success) {

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TYPEDOC_DATA,
          payload: data.typeDoc_LIST
        });

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    //  console.log("Error****:", error.message);
      //window.location.replace("/");
    });
  }
};
