
import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import {
  THEME_TYPE_LITE
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";

const YDEO_ADV = ({defaultOpenKeys, selectedKeys, themeType}) =>

<Menu
  defaultOpenKeys={[defaultOpenKeys]}
  selectedKeys={[selectedKeys]}
  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
  mode="inline">

    <Menu.Item key="dashboard">
      <Link to="/dashboard_staff"><i className="icon icon-widgets"/>
        <IntlMessages id="sidebar.dashboard"/></Link>
    </Menu.Item>

    <Menu.Item key="clients">
      <Link to="/clients"><i className="icon icon-contacts"/>
        <IntlMessages id="sidebar.Clients"/></Link>
    </Menu.Item>

    <Menu.Item key="moncompte">
      <Link to="/moncompte"><i className="icon icon-user"/>
        <IntlMessages id="sidebar.InfoPerso"/></Link>
    </Menu.Item>

    <Menu.Item key="suivi_technique_admin">
      <Link to="/suivi_technique_admin"><i className="icon icon-select"/>
        <IntlMessages id="sidebar.suivi_technique"/></Link>
    </Menu.Item>

    <Menu.Item key="document_ft">
      <Link to="/admin_document_ft"><i className="icon icon-ckeditor"/>
        <IntlMessages id="sidebar.documents.FT"/></Link>
    </Menu.Item>

    <Menu.Item key="document_documentation">
      <Link to="/document_documentation"><i className="icon icon-ckeditor"/>
        <IntlMessages id="sidebar.documents.DOCUMENTATION"/></Link>
    </Menu.Item>



</Menu>

export default YDEO_ADV;
