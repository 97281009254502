import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  CLIENTS_DATA,
  CLIENTS_DATA_CLEAN,
} from "../../constants/ActionTypes";

import axios from 'util/Api'

function RequestCONTROLLER(type, data, dispatch){
  if(type === 'ERROR'){
    if(data.config){
      //console.log(data.config.url)
      //console.log(data.config.method)
      switch(data.request.status){
        case 0:
          console.log('NO CONNECTION')
          break
        case 400:
          console.log('BAD REQUEST')
          break
        case 401:
          localStorage.removeItem("token");
          console.log('AUTHENTIFICATION REQUIRED')
          //window.location.replace("/refresh-session");
          break
        case 403:
          console.log('NOT AUTHORIZED')
        case 500:
          console.log('INTERNAL_ERROR')
          break
        case 502:
          console.log('SERVER_DOWN')
          break

        default:
          console.log('no event')
      }
    }else{
      console.log('Type Error')
      //console.log(data)
    }
  }
}

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getClients = (offset,quantity,isAllClient, IsClientAActiver) => {

  var vl_isAllClient = 0;

  if(isAllClient === undefined){
    vl_isAllClient = 0;
  }else{
    vl_isAllClient = isAllClient
  }

//  console.log('Avant envoi !!!!' + offset + ' ' + quantity + ' ' +isAllClient)

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: CLIENTS_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    axios.get(`clients/${offset}/${quantity}/${vl_isAllClient}/${IsClientAActiver}`)
    .then(({data}) => {
      if (data.success) {
        //console.log(data.clients_LIST);
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: CLIENTS_DATA,
          payload: data.clients_LIST,
          offset:offset + quantity
        });

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});

    });
  }
};

export const searchClients = (offset, quantity, isAllClient, IsClientAActiver, pe_textSearch ) => {


  var vl_isAllClient = 0;

  if(isAllClient == undefined){
    vl_isAllClient = 0;
  }else{
    vl_isAllClient = isAllClient
  }

  //console.log('Avant envoi !!!!' + offset + ' ' + quantity + ' ' +isAllClient + ' ' + pe_textSearch)

  return (dispatch) => {

    if(offset === 0){
      dispatch({
        type: CLIENTS_DATA_CLEAN,
        payload: [],
        offset:offset + quantity
      });
    }

    dispatch({type: FETCH_START});
    axios.get(`clients/search/${offset}/${quantity}/${vl_isAllClient}/${IsClientAActiver}/${pe_textSearch}`)
    .then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});

        dispatch({
          type: CLIENTS_DATA,
          payload: data.clients_LIST,
          offset:offset + quantity
        });

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      RequestCONTROLLER('ERROR',error, dispatch);
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};
