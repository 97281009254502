import React from "react";
import {Button, Form, Input, Checkbox} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {getAPI_KEY_CAPTCHA} from "util/API_Utilities";

const DELAY = 1000

const FormItem = Form.Item;

class SignIn extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      _reCaptchaRef:null,
      SITE_KEY :'',
      param : '',
      isHidenPassword : false,
      hidenPassword : 'password',
    };
  }

  onChangePassword = (e) => {

    let hidenPassword = '';
    if(e.target.checked == true){
      hidenPassword = '';
    }else{
      hidenPassword = 'password';
    }

    this.setState({isHidenPassword : e.target.checked, hidenPassword : hidenPassword});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.captcha_token = this.state.value
        values.isGoHomePage = false;
        this.props.userSignIn(values);
      }
    });
  };


  GetURLImgParam(){

    // Suivant un paramètre dans l'URL on charge une image diffétente (exemple LBE)
    switch (this.state.param) {
      case 'lbe':
        return require(`assets/images/IMG_PARAM/logo_lbe.png`);
        break;
      default:
        return require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`);
    }
  }

  componentDidUpdate() {

    this.state.SITE_KEY =  getAPI_KEY_CAPTCHA();

    let url = this.props.location.search;

    var info = '';

    if(url === '?param=lbe' ){
      console.log('Societé la belle époque')
      this.state.param ='lbe'
    }

    if (this.props.token !== null) {
      this.props.history.push('/');
    }

    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);

  }

  handleChange = value => {

    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    //console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <>
      <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
        <div className="gx-app-login-container" >
          <div className="gx-app-login-main-content" >
            <div className="gx-app-logo-content">

              <div className="gx-app-logo-wid">
              <div className="gx-app-logo">
                <img style = {{marginBottom : 10}} src={this.GetURLImgParam()}/>
              </div>
                <div className = "gx-text-center gx-flex-column gx-justify-content-center">
                  <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                  <p><IntlMessages id="app.userAuth.bySigning"/></p>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-shopping-cart"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Commandes</div>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-attachment"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Bons livraison</div>
                </div>

                <div style = {{fontSize:20}}>
                  <i style = {{fontSize:25}} className="icon-orders"/>
                  <div style = {{marginLeft:35, marginTop : -33, marginBottom: 10}} >Factures</div>
                </div>

              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/ydeo_connect.png`)}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: `Cette adresse e-mail n'est pas valide`,
                    }],
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>


                  <FormItem >
                    {getFieldDecorator('password', {
                      initialValue: "",
                      rules: [{required: true, message: 'Entrer votre mot de passe'}],
                    })(
                      <Input iconRender={true} type={this.state.hidenPassword} placeholder="Mot de passe"/>
                    )}

                    <Checkbox onChange={this.onChangePassword}>Afficher votre mot de passe</Checkbox>

                  </FormItem>

                {this.state.load && (
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      theme="white"
                      ref={this.state._reCaptchaRef}
                      sitekey={this.state.SITE_KEY}
                      onChange={this.handleChange}
                      asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                  )}

                <FormItem>
                  <Button background-color="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>

                  <div style={{marginTop:5, fontSize: 14}}>
                    <Link to="/reset-password"><IntlMessages id="app.userAuth.forgetPassword"/></Link>
                  </div>
                </FormItem>


                <div style = {{marginTop: -20}}>
                  <span
                    className="gx-text-light gx-fs-sm"> <IntlMessages id="app.userAuth.infologin"/>
                  </span>
                </div>

              </Form>


            </div>
            <InfoView/>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
