import './polyfills';

import React from "react";
import ReactDOM from "react-dom";
import NextApp from './NextApp';
import withClearCache from "./ClearCache";
import * as Sentry from '@sentry/react';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import {AppContainer} from 'react-hot-loader';
import 'react-app-polyfill/ie11';

const ClearCacheComponent = withClearCache(NextApp);




Sentry.init({dsn: "https://8db35d02929d495fb22fb9d689792fe5@o404326.ingest.sentry.io/5306857"});

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <ClearCacheComponent/>
    </AppContainer>,
    document.getElementById('root')
  );
};


//console.log('START !!!!');
// On charge le fichier de configuration suivant la societé passée en paramètre

// Do this once
registerServiceWorker();
//registerServiceWorker.unregister();
// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
