import React from "react";
import {Button, Form, Input} from "antd";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {resetPassword} from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class ResetPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        this.props.resetPassword(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src='https://via.placeholder.com/272x395' alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.ResetPassword.title"/></h1>
                <p><IntlMessages id="app.userAuth.ResetPassword.description"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('Email', {
                    rules: [{
                      required: true,
                       type: 'email',
                       message: `Cette adresse e-mail n'est pas valide`,
                    }],
                  })(
                    <Input placeholder="Entrer votre adresse e-mail"/>
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.ResetPassword.action"/>
                  </Button>

                    <div style={{marginTop:5}}>
                      <Link style={{fontSize:15}} to="/signin"><IntlMessages  id="app.userAuth.signIn"/></Link>
                    </div>

                </FormItem>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );

  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {resetPassword})(WrappedResetPasswordForm);
