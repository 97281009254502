import React from "react";
import {Button, Form, Input, Checkbox} from "antd";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {newPassword} from "../appRedux/actions/Auth";
import {message} from 'antd';

import { hasUpperCase2, hasSpecialCaracters, hasNumber } from 'util/Toolbox'

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class NewPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      GUID:'',
      password : '',
      passwordConfirm : '',
      isHidenPassword : false,
      hidenPassword : 'password',
    }
  }


  onChangePassword = (e) => {

    let hidenPassword = '';
    if(e.target.checked == true){
      hidenPassword = '';
    }else{
      hidenPassword = 'password';
    }

    this.setState({isHidenPassword : e.target.checked, hidenPassword : hidenPassword});
  }

  renderInfoPassword(pe_tab){
    return (
      <div>
        <div>
            {pe_tab}
        </div>
      </div>
    );
  }

  strongValidatorPassword = (rule, value, callback) => {

    const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+=/.@$%^&*)-]).{10,}$`);
    this.state.password = value;
    if(!value.match(strongRegex) ) {
      return callback(true)
    }
    callback()
}


  strongValidatorPasswordConfirm = (rule, value, callback) => {

    const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!+=/.@$%^&*)-]).{10,}$`);
    this.state.passwordConfirm = value;
    if(!value.match(strongRegex) ) {
      return callback(true)
    }
    callback()
  }


    GenerateMessage(pe_text) {

      let tabMessage     = [];

      tabMessage.push(<p>Votre mot de passe doit contenir: </p>);

      if(pe_text.length < 10){
        tabMessage.push(<p>- au moins 10 caractères </p>);
      }else{
        tabMessage.push(<p style={{ color: 'green'}}>- au moins 10 caractères </p>);
      }

      if(! hasUpperCase2(pe_text)){
        tabMessage.push(<p>- 1 Majuscule </p>);
      }else{
        tabMessage.push(<p style={{ color: 'green'}}>- 1 Majuscule </p>);
      }

      if(! hasNumber(pe_text) ){
        tabMessage.push(<p>- 1 chiffre</p>);
      }else{
        tabMessage.push(<p style={{ color: 'green'}}>- 1 chiffre </p>);
      }

      if(! hasSpecialCaracters(pe_text) ){
        tabMessage.push(<p>- 1 caractère spécial (*!+$)</p>);
      }else{
        tabMessage.push(<p style={{ color: 'green'}}>- 1 caractère spécial (*!+$) </p>);
      }

      return tabMessage;

    }


    renderMessageErrorPassword = () => {

      let tabMessage    = [];
      tabMessage        = this.GenerateMessage(this.state.password);

      return this.renderInfoPassword(tabMessage);
    }


    renderMessageErrorPasswordConfirm = () => {

      let tabMessage    = [];
      tabMessage        = this.GenerateMessage(this.state.passwordConfirm);
      return this.renderInfoPassword(tabMessage);

    }


    handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      var isOK = true;

      // Control si mot de passe identique
      if(values.Password === values.Password_confirm){
        isOK = true;
      }else{
        isOK = false
        console.log('Les mots de passes doivent être identique !!!!')
        this.SendMessageError('Les mots de passe doivent être identiques!');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length >= 10){
        isOK = true;
      }else{
        isOK = false
        console.log('La longueur du mot de passe doit être supérieure à 10 caractères !')
        this.SendMessageError('La longueur du mot de passe doit être supérieure à 10 caractères !');
        return;
      }

      // Control la taille des mots de passes
      if(values.Password.length <= 30){
        isOK = true;
      }else{
        isOK = false
        console.log('La longueur du mot de passe doit être inférieure à 30 caractères !')
        this.SendMessageError('La longueur du mot de passe doit être inférieure à 30 caractères !');
        return;
      }


      const strongRegex = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$+%^&*-]).{10,}$`);

      if(!values.Password.match(strongRegex) ) {
        isOK = false
        console.log('Entrer votre mot de passe: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial !!!!')
        this.SendMessageError('Votre mot de passe doit contenir: 10 caractères, 1 majuscule, 1 chiffre, 1 caractère spécial (*!+$)');
        return;
      }




      values.GUID = this.state.GUID;
      if (!err) {
        this.props.newPassword(values);
      }
    });
  };


    SendMessageError(pe_message){
    message.error(pe_message);
  }


    componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }

    const location = window.location.href
    const path = location.split('/');
    const GUID_ = path[path.length - 1]

    this.state.GUID =GUID_;

  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap" style={{backgroundImage: `url(${require(`assets/images/${process.env.REACT_APP_ENV}/bg-home.jpg`)})`}}>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                  <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.ChangePassword.title"/></h1>
                <p><IntlMessages id="app.userAuth.ChangePassword.description"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require(`assets/images/${process.env.REACT_APP_ENV}/logo.png`)}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('Password', {
                  rules: [{required: true, message: this.renderMessageErrorPassword, validator: this.strongValidatorPassword}],
                })(
                  <Input type="password" placeholder="Mot de passe"/>
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('Password_confirm', {
                  rules: [{required: true, message: this.renderMessageErrorPasswordConfirm, validator: this.strongValidatorPasswordConfirm},
                ],
                })(
                  <Input type={this.state.hidenPassword}  placeholder="Mot de passe"/>
                )}
              </FormItem>

              <Checkbox onChange={this.onChangePassword}>Afficher votre mot de passe</Checkbox>

              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.ChangePassword.action"/>
                </Button>
              </FormItem>

              <div style={{marginTop:5}}>
                <Link style={{fontSize:14}} to="/signin"><IntlMessages  id="app.userAuth.signIn"/></Link>
              </div>

              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );

  }
}

const WrappedNewPasswordForm = Form.create()(NewPassword);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {newPassword})(WrappedNewPasswordForm);
