
import React        from "react";
import {connect}    from "react-redux";
import {Menu}       from "antd";
import {Link}       from "react-router-dom";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";

const SubMenu           = Menu.SubMenu;
const MenuItemGroup     = Menu.ItemGroup;

const COMPANY_COMPTA = ({defaultOpenKeys, selectedKeys, themeType, IsAccesSuiviTechnique, IsChiffreMasque}) =>

/*
<Menu.Item key="document_formation">
  <Link to="/document_formation"><i className="icon icon-ckeditor"/>
    <IntlMessages id="sidebar.documents.FORMATION"/></Link>
</Menu.Item>
*/
<Menu
  defaultOpenKeys={[defaultOpenKeys]}
  selectedKeys={[selectedKeys]}
  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
  mode="inline"
  style = {{fontSize:'12px'}}>
    <Menu.Item key="dashboard">
      <Link to="/dashboard"><i className="icon icon-widgets"/>
        <IntlMessages id="sidebar.dashboard"/></Link>
    </Menu.Item>

    <Menu.Item key="commande">
      <Link to="/commande"><i className="icon icon-shopping-cart"/>
        <IntlMessages id="sidebar.commandes"/></Link>
    </Menu.Item>

    <Menu.Item key="bon_livraison">
      <Link to="/bon_livraison"><i className="icon icon-attachment"/>
        <IntlMessages id="sidebar.BL"/></Link>
    </Menu.Item>


    { IsChiffreMasque == 0 &&
      <Menu.Item key="facture">
        <Link to="facture"><i className="icon icon-orders"/>
          <IntlMessages id="sidebar.Factures"/></Link>
      </Menu.Item>
    }

    <Menu.Item key="adresses">
      <Link to="/adresses"><i className="icon icon-map-google"/>
        <IntlMessages id="sidebar.Adresses"/></Link>
    </Menu.Item>

    <SubMenu key="documents"  title={
              <span> <i className="icon icon-ckeditor"/>

              <IntlMessages id="sidebar.documents"/>
               </span>}>

              <Menu.Item key="document_ft">
                <Link to="/document_ft"><i className="icon icon-ckeditor"/>
                  <IntlMessages id="sidebar.documents.FT"/></Link>
              </Menu.Item>


              <Menu.Item key="document_rse">
                <Link to="/document_rse"><i className="icon icon-ckeditor"/>
                  <IntlMessages id="sidebar.documents.RSE"/></Link>
              </Menu.Item>

              <Menu.Item key="document_marketing">
                <Link to="/document_marketing"><i className="icon icon-ckeditor"/>
                  <IntlMessages id="sidebar.document_marketing"/></Link>
              </Menu.Item>

    </SubMenu>


    { IsAccesSuiviTechnique == 1 &&
      <Menu.Item key="suivi_technique">
        <Link to="/suivi_technique"><i className="icon icon-select"/>
          <IntlMessages id="sidebar.suivi_technique"/></Link>
      </Menu.Item>
    }

    <Menu.Item key="moncompte">
      <Link to="/moncompte"><i className="icon icon-user"/>
        <IntlMessages id="sidebar.InfoPerso"/></Link>
    </Menu.Item>

    <Menu.Item key="administration">
      <Link to="/administration"><i className="icon icon-auth-screen"/>
        <IntlMessages id="sidebar.Administration"/></Link>
    </Menu.Item>
</Menu>

export default COMPANY_COMPTA;
