
import {
  INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET,
  USER_INVOICE_DATA,
  USER_ADDRESS_DATA,
  USER_DELIVERYNOTE_DATA,
  USER_ORDERS_DATA,
  USER_ADMINISTRATION_DATA,
  USER_REGISTERING_SET,
  USER_ORDERS_DATA_CLEAN,
  USER_DASHBOARD_DATA,
  USER_DASHBOARD_STAFF_DATA,
  USER_ORDERSLINES_DATA,
  USER_ORDERSLINES_DATA_CLEAN,
  USER_DATA_CLEAN,
  USER_ITEMS_DATA,
  USER_ITEMS_DATA_CLEAN,
  STAFF_USER_SET,
  CLEAR_ONLY_DATA,
  USER_ADMINISTRATION_DATA_CLEAN,
  USER_INVOICE_DATA_CLEAN,
  USER_TYPEDOC_DATA,
  USER_TYPEDOC_FDS_DATA,
  USER_TYPEDOC_FT_DATA,
  USER_TYPEDOC_FT_DATA_CLEAN,
  USER_TYPEDOC_FDS_DATA_CLEAN,
  USER_TYPEDOC_OFFICIEL_DATA,
  USER_TYPEDOC_OFFICIEL_DATA_CLEAN,
  ITEMS_DATA_CLEAN,
  USER_TYPEDOC_RSE_DATA,
  USER_TYPEDOC_RSE_DATA_CLEAN,
  USER_TYPEDOC_PLAQUETTE_DATA,
  USER_TYPEDOC_PLAQUETTE_DATA_CLEAN,
  USER_TYPEDOC_FORMATION_DATA,
  USER_TYPEDOC_FORMATION_DATA_CLEAN,

  USER_TYPEDOC_DOCUMENTATION_DATA,
  USER_TYPEDOC_DOCUMENTATION_DATA_CLEAN

} from "../../constants/ActionTypes";



export const INIT_STATE = {

  token: localStorage.getItem('token'),
  IDEmploye: localStorage.getItem('staff_member'),
  scopeLib: localStorage.getItem('scopeLib'),
  IDCompteAtlas: localStorage.getItem('IDCompteAtlas'),
  IsAccesSuiviTechnique: localStorage.getItem('IsAccesSuiviTechnique'),

  IsChiffreMasque: localStorage.getItem('IsChiffreMasque'),

  guid:'',
  initURL: '',
  authUser: '',

  invoice_LIST: [],
  invoice_LIST_OFFSET:0,
  invoice_LIST_QUANTITY:20,

  address_LIST:[],
  address_LIST_OFFSET:0,
  address_LIST_QUANTITY:20,

  deliverynote_LIST:[],
  deliverynote_LIST_OFFSET:0,
  deliverynote_LIST_QUANTITY:20,

  orders_LIST:[],
  orders_LIST_OFFSET:0,
  orders_LIST_QUANTITY:20,
  ordersInfo:{},

  administration_LIST:[],
  administration_LIST_OFFSET:0,
  administration_LIST_QUANTITY:20,

  dashboard_LIST: [],

  dashboardStaff_LIST : [],

  orderslines_LIST:[],

  typeDoc_LIST : [],

  typeDocFT_LIST : [],
  typeDocFT_LIST_OFFSET:0,
  typeDocFT_LIST_QUANTITY:20,

  typeDocFDS_LIST : [],
  typeDocFDS_LIST_OFFSET:0,
  typeDocFDS_LIST_QUANTITY:20,

  typeDocOFFICIEL_LIST : [],
  typeDocOFFICIEL_OFFSET:0,
  typeDocOFFICIEL_QUANTITY:20,

  typeDocRSE_LIST : [],
  typeDocRSE_OFFSET:0,
  typeDocRSE_LIST_QUANTITY:20,

  typeDocPLAQUETTE_LIST : [],
  typeDocPLAQUETTE_LIST_OFFSET:0,
  typeDocPLAQUETTE_LIST_QUANTITY:20,

  registering_account:null,

  items_LIST:[],
  items_LIST_OFFSET:0,
  items_LIST_QUANTITY:20,
  items_LIST_HIDE : [],

  typeDocFORMATION_LIST : [],
  typeDocFORMATION_LIST_OFFSET:0,
  typeDocFORMATION_LIST_QUANTITY:20,

  typeDocDOCUMENTATION_LIST : [],
  typeDocDOCUMENTATION_LIST_OFFSET:0,
  typeDocDOCUMENTATION_LIST_QUANTITY:20,

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_DASHBOARD_DATA: {
      return {
        ...state,
        dashboard_LIST: action.payload
      };
    }

    case USER_DASHBOARD_STAFF_DATA: {
      return {
        ...state,
        dashboardStaff_LIST: action.payload
      };
    }

    case USER_INVOICE_DATA: {
      return {
        ...state,
        invoice_LIST: removeDuplicates(state.invoice_LIST.concat(action.payload)),
        invoice_LIST_OFFSET:action.offset
      };
    }

    case USER_ADDRESS_DATA: {
      return {
        ...state,
        address_LIST: removeDuplicates(state.address_LIST.concat(action.payload)),
        address_LIST_OFFSET:action.offset
      };
    }

    case USER_DELIVERYNOTE_DATA: {
      return {
        ...state,
        deliverynote_LIST: removeDuplicates(state.deliverynote_LIST.concat(action.payload)),
        deliverynote_LIST_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_FT_DATA: {
      return {
        ...state,
        typeDocFT_LIST: removeDuplicates(state.typeDocFT_LIST.concat(action.payload)),
        typeDocFT_LIST_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_FDS_DATA: {
      return {
        ...state,
        typeDocFDS_LIST: removeDuplicates(state.typeDocFDS_LIST.concat(action.payload)),
        typeDocFDS_LIST_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_RSE_DATA: {
      return {
        ...state,
        typeDocRSE_LIST: removeDuplicates(state.typeDocRSE_LIST.concat(action.payload)),
        typeDocRSE_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_PLAQUETTE_DATA: {
      return {
        ...state,
        typeDocPLAQUETTE_LIST: removeDuplicates(state.typeDocPLAQUETTE_LIST.concat(action.payload)),
        typeDocPLAQUETTE_LIST_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_FORMATION_DATA: {
      return {
        ...state,
        typeDocFORMATION_LIST: removeDuplicates(state.typeDocFORMATION_LIST.concat(action.payload)),
        typeDocFORMATION_LIST_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_OFFICIEL_DATA: {
      return {
        ...state,
        typeDocOFFICIEL_LIST: removeDuplicates(state.typeDocOFFICIEL_LIST.concat(action.payload)),
        typeDocOFFICIEL_OFFSET:action.offset
      };
    }

    case USER_TYPEDOC_DOCUMENTATION_DATA: {
      return {
        ...state,
        typeDocDOCUMENTATION_LIST: removeDuplicates(state.typeDocDOCUMENTATION_LIST.concat(action.payload)),
        typeDocDOCUMENTATION_LIST_OFFSET:action.offset
      };
    }

    case USER_ITEMS_DATA: {
      return {
        ...state,
        items_LIST:  removeHideItem( removeDuplicates(state.items_LIST.concat(action.payload)) , action.items_LIST_HIDE ),
        items_LIST_OFFSET:action.offset,
      };
    }

    case USER_ITEMS_DATA_CLEAN: {
      return {
        ...state,
        items_LIST: [],
      };
    }

    case USER_TYPEDOC_FT_DATA_CLEAN: {
      return {
        ...state,
        typeDocFT_LIST: [],
      };
    }

    case USER_TYPEDOC_RSE_DATA_CLEAN: {
      return {
        ...state,
        typeDocRSE_LIST: [],
      };
    }

    case USER_TYPEDOC_PLAQUETTE_DATA_CLEAN: {
      return {
        ...state,
        typeDocPLAQUETTE_LIST: [],
      };
    }

    case USER_TYPEDOC_FDS_DATA_CLEAN: {
      return {
        ...state,
        typeDocFDS_LIST: [],
      };
    }

    case USER_TYPEDOC_OFFICIEL_DATA_CLEAN: {
      return {
        ...state,
        typeDocOFFICIEL_LIST: [],
      };
    }

    case USER_TYPEDOC_FORMATION_DATA_CLEAN: {
      return {
        ...state,
        typeDocFORMATION_LIST: [],
      };
    }


    case USER_TYPEDOC_DOCUMENTATION_DATA_CLEAN: {
      return {
        ...state,
        typeDocDOCUMENTATION_LIST: [],
      };
    }

    case ITEMS_DATA_CLEAN: {
      return {
        ...state,
        items_LIST: [],
      };
    }



    case USER_INVOICE_DATA_CLEAN: {
      return {
        ...state,
        invoice_LIST: [],
      };
    }

    case USER_ORDERS_DATA: {
      return {
        ...state,
        orders_LIST: removeDuplicates(state.orders_LIST.concat(action.payload)),
        orders_LIST_OFFSET:action.offset,
        ordersInfo : action.ordersInfo
      };
    }

    case USER_ORDERSLINES_DATA: {
      return {
        ...state,
        orderslines_LIST: removeDuplicates(state.orderslines_LIST.concat(action.payload)),
      };
    }

    case USER_TYPEDOC_DATA: {
      return {
        ...state,
        typeDoc_LIST: removeDuplicates(state.typeDoc_LIST.concat(action.payload)),
      };
    }

    case USER_ORDERSLINES_DATA_CLEAN: {
      return {
        ...state,
        orderslines_LIST: [],
      };
    }

    case USER_ADMINISTRATION_DATA_CLEAN: {
      return {
        ...state,
        administration_LIST: [],

      };
    }

    case USER_DATA_CLEAN: {
      return {
        ...state,
        INIT_STATE
      };
    }

    case CLEAR_ONLY_DATA: {
      return {
        ...state,
        authUser: '',
        invoice_LIST: [],
        invoice_LIST_OFFSET:0,
        invoice_LIST_QUANTITY:20,

        dashboardStaff_LIST : [],
        dashboard_LIST : [],

        address_LIST:[],
        address_LIST_OFFSET:0,
        address_LIST_QUANTITY:20,

        deliverynote_LIST:[],
        deliverynote_LIST_OFFSET:0,
        deliverynote_LIST_QUANTITY:20,

        orders_LIST:[],
        orders_LIST_OFFSET:0,
        orders_LIST_QUANTITY:20,

        administration_LIST:[],
        administration_LIST_OFFSET:0,
        administration_LIST_QUANTITY:20,

        orderslines_LIST:[],

        items_LIST:[],
        items_LIST_OFFSET:0,
        items_LIST_QUANTITY:20,
        items_LIST_HIDE : [],


        typeDocFT_LIST : [],
        typeDocFDS_LIST : [],

        typeDocRSE_LIST : [],

        typeDocFT_LIST_OFFSET : 0,
        typeDocFDS_LIST_OFFSET : 0,

        typeDocOFFICIEL_LIST : [],
        typeDocOFFICIEL_OFFSET:0,

        typeDocDOCUMENTATION_LIST : [],
        typeDocDOCUMENTATION_LIST_OFFSET : 0,

      }
    }


    case USER_ORDERS_DATA_CLEAN: {
      return {
        ...state,
        orders_LIST: [],
        orders_LIST_OFFSET:action.offset
      };
    }

    case USER_ADMINISTRATION_DATA: {
      return {
        ...state,
        administration_LIST: removeDuplicates(state.administration_LIST.concat(action.payload)),
        administration_LIST_OFFSET:action.offset
      };
    }

    case USER_TOKEN_SET: {
      if(action.payload.IDCompteAtlas){
        return {
          ...state,
          token: action.payload.token,
          IDEmploye: action.payload.IDEmploye,
          scopeLib : action.payload.scopeLib,
          IDCompteAtlas : action.payload.IDCompteAtlas,
          IsAccesSuiviTechnique : action.payload.IsAccesSuiviTechnique,
          IsChiffreMasque : action.payload.IsChiffreMasque,

        };
      }else{
        return {
          ...state,
          token: action.payload.token,
          scopeLib : action.payload.scopeLib,
          IsChiffreMasque : action.payload.IsChiffreMasque,
        };
      }
    }

    case STAFF_USER_SET: {
      return {
        ...state,
        IDEmploye: action.payload,
      };
    }

    case USER_REGISTERING_SET: {
      return {
        ...state,
        registering_account: action.payload,
      };
    }

    default:
      return state;
  }
}


function removeDuplicates(json) {
    const jsonObject = json.map(JSON.stringify);
    const uniqueSet = new Set(jsonObject);
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    return uniqueArray
}

function removeHideItem(list, list_hide) {
  for(var i = list.length - 1; i >= 0; i--) {
     for (var j = 0; j < list_hide.length; j++) {
        if(list[i].CodeArticle.toString() == list_hide[j].CodeArticle.toString()){
           console.log('Supression !!!')
            list.splice(i, 1);
         }
      }
    }
    return list
  }
