import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

//**************************************************************************
//--------------------------  YDEO STAFF ROUTES ---------------------------
//**************************************************************************


export const StaffPowerRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}adresses`} component={asyncComponent(() => import('./AdressesPage'))}/>
      <Route path={`${match.url}commande`} component={asyncComponent(() => import('./CommandePage'))}/>
      <Route path={`${match.url}commande_fiche`} component={asyncComponent(() => import('./CommandeFiche'))}/>
      <Route path={`${match.url}commande_panier`} component={asyncComponent(() => import('./CommandePanier'))}/>
      <Route path={`${match.url}document_ft`} component={asyncComponent(() => import('./DocumentFT_Page'))}/>
      <Route path={`${match.url}document_fds`} component={asyncComponent(() => import('./DocumentFDS_Page'))}/>
      <Route path={`${match.url}suivi_technique`} component={asyncComponent(() => import('./SuiviTechnique_Page'))}/>
      <Route path={`${match.url}document_officiel`} component={asyncComponent(() => import('./DocumentOFFICIEL_Page'))}/>
      <Route path={`${match.url}document_rse`} component={asyncComponent(() => import('./DocumentRSE_Page'))}/>
      <Route path={`${match.url}document_marketing`} component={asyncComponent(() => import('./DocumentMARKETING_Page'))}/>
      <Route path={`${match.url}administration`} component={asyncComponent(() => import('./AdminPage'))}/>
      <Route path={`${match.url}suivi_technique_admin`} component={asyncComponent(() => import('./BackPage/SuiviTechnique_Page'))}/>
      <Route path={`${match.url}document_documentation`} component={asyncComponent(() => import('./BackPage/DocumentDocumentation_Page'))}/>
    </Switch>
  </div>
);

// <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
export const StaffRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
      <Route path={`${match.url}dashboard_staff`} component={asyncComponent(() => import('./BackPage/DashboardPage'))}/>
      <Route path={`${match.url}clients`} component={asyncComponent(() => import('./ClientsPage'))}/>
      <Route path={`${match.url}admin_document_ft`} component={asyncComponent(() => import('./BackPage/DocumentFT_Page'))}/>
      <Route path={`${match.url}moncompte`} component={asyncComponent(() => import('./InfoPersoPage'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}adresses`} component={asyncComponent(() => import('./AdressesPage'))}/>
      <Route path={`${match.url}commande`} component={asyncComponent(() => import('./CommandePage'))}/>
      <Route path={`${match.url}commande_fiche`} component={asyncComponent(() => import('./CommandeFiche'))}/>
      <Route path={`${match.url}commande_panier`} component={asyncComponent(() => import('./CommandePanier'))}/>
      <Route path={`${match.url}document_ft`} component={asyncComponent(() => import('./DocumentFT_Page'))}/>
      <Route path={`${match.url}document_fds`} component={asyncComponent(() => import('./DocumentFDS_Page'))}/>
      <Route path={`${match.url}suivi_technique`} component={asyncComponent(() => import('./SuiviTechnique_Page'))}/>
      <Route path={`${match.url}document_rse`} component={asyncComponent(() => import('./DocumentRSE_Page'))}/>
      <Route path={`${match.url}document_officiel`} component={asyncComponent(() => import('./DocumentOFFICIEL_Page'))}/>
      <Route path={`${match.url}document_marketing`} component={asyncComponent(() => import('./DocumentMARKETING_Page'))}/>
      <Route path={`${match.url}administration`} component={asyncComponent(() => import('./AdminPage'))}/>
      <Route path={`${match.url}suivi_technique_admin`} component={asyncComponent(() => import('./BackPage/SuiviTechnique_Page'))}/>
      <Route path={`${match.url}document_documentation`} component={asyncComponent(() => import('./BackPage/DocumentDocumentation_Page'))}/>
    </Switch>
  </div>
);



//**************************************************************************
//--------------------------  USER CLASSIC ROUTES ---------------------------
//**************************************************************************


export const CompanyAdminRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
      <Route path={`${match.url}dashboard2`} component={asyncComponent(() => import('./DashboardPageV2'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}adresses`} component={asyncComponent(() => import('./AdressesPage'))}/>
      <Route path={`${match.url}moncompte`} component={asyncComponent(() => import('./InfoPersoPage'))}/>
      <Route path={`${match.url}commande`} component={asyncComponent(() => import('./CommandePage'))}/>
      <Route path={`${match.url}commande_fiche`} component={asyncComponent(() => import('./CommandeFiche'))}/>
      <Route path={`${match.url}commande_panier`} component={asyncComponent(() => import('./CommandePanier'))}/>
      <Route path={`${match.url}administration`} component={asyncComponent(() => import('./AdminPage'))}/>
      <Route path={`${match.url}document_ft`} component={asyncComponent(() => import('./DocumentFT_Page'))}/>
      <Route path={`${match.url}document_formation`} component={asyncComponent(() => import('./DocumentFORMATION_Page'))}/>
      <Route path={`${match.url}document_fds`} component={asyncComponent(() => import('./DocumentFDS_Page'))}/>
      <Route path={`${match.url}document_officiel`} component={asyncComponent(() => import('./DocumentOFFICIEL_Page'))}/>
      <Route path={`${match.url}document_rse`} component={asyncComponent(() => import('./DocumentRSE_Page'))}/>
      <Route path={`${match.url}document_marketing`} component={asyncComponent(() => import('./DocumentMARKETING_Page'))}/>
      <Route path={`${match.url}suivi_technique`} component={asyncComponent(() => import('./SuiviTechnique_Page'))}/>
    </Switch>
  </div>
);


export const CompanyBuyerRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}adresses`} component={asyncComponent(() => import('./AdressesPage'))}/>
      <Route path={`${match.url}moncompte`} component={asyncComponent(() => import('./InfoPersoPage'))}/>
      <Route path={`${match.url}commande`} component={asyncComponent(() => import('./CommandePage'))}/>
      <Route path={`${match.url}commande_fiche`} component={asyncComponent(() => import('./CommandeFiche'))}/>
      <Route path={`${match.url}commande_panier`} component={asyncComponent(() => import('./CommandePanier'))}/>
      <Route path={`${match.url}document_ft`} component={asyncComponent(() => import('./DocumentFT_Page'))}/>
      <Route path={`${match.url}document_fds`} component={asyncComponent(() => import('./DocumentFDS_Page'))}/>
      <Route path={`${match.url}document_officiel`} component={asyncComponent(() => import('./DocumentOFFICIEL_Page'))}/>
      <Route path={`${match.url}document_rse`} component={asyncComponent(() => import('./DocumentRSE_Page'))}/>
      <Route path={`${match.url}document_marketing`} component={asyncComponent(() => import('./DocumentMARKETING_Page'))}/>
      <Route path={`${match.url}suivi_technique`} component={asyncComponent(() => import('./SuiviTechnique_Page'))}/>
    </Switch>
  </div>
);


export const CompanyComptaRoutes = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/>
      <Route path={`${match.url}facture`} component={asyncComponent(() => import('./FacturePage'))}/>
      <Route path={`${match.url}bon_livraison`} component={asyncComponent(() => import('./BonLivraisonPage'))}/>
      <Route path={`${match.url}moncompte`} component={asyncComponent(() => import('./InfoPersoPage'))}/>
    </Switch>
  </div>
);
