// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';


export const STAFF_SESSION_CLIENT = 'STAFF_SESSION_CLIENT'

export const CLIENTS_DATA = 'CLIENTS_DATA'

export const CLIENTS_DATA_CLEAN = 'CLIENTS_DATA_CLEAN'

export const STAFF_USER_SET = 'STAFF_USER_SET'


export const USER_DATA = 'user_data';
export const USER_INVOICE_DATA = 'user_invoice_data';
export const USER_ADDRESS_DATA = 'user_address_data'
export const USER_DELIVERYNOTE_DATA = 'user_bonlivraison_data';
export const USER_ORDERS_DATA = 'user_orders_data'
export const USER_ORDERS_DATA_CLEAN = 'user_orders_data_clean'


export const USER_DELIVERYNOTE_DATA_CLEAN = 'user_bonlivraison_data_clean';


export const USER_ADMINISTRATION_DATA = 'user_administration_data'

export const USER_TOKEN_SET = 'user_token_set';
export const USER_REGISTERING_SET = 'user_guid_set';

export const USER_DASHBOARD_DATA = 'user_dashboard_data'

export const USER_DASHBOARD_STAFF_DATA = 'user_dashboard_staff_data'

export const USER_ORDERSLINES_DATA = 'user_orderslines_data'

export const USER_ORDERSLINES_DATA_CLEAN = 'user_orderslines_data_clean'


export const USER_INVOICE_DATA_CLEAN = 'user_invoice_data_clean'

export const USER_DATA_CLEAN                          = 'user_data_clean'
export const USER_ADMINISTRATION_DATA_CLEAN           = 'user_administration_data_clean'
export const USER_ITEMS_DATA                          = 'user_items_data'
export const USER_ITEMS_DATA_CLEAN                    = 'user_items_data_clean'
export const CLEAR_ONLY_DATA                          = 'CLEAR_ONLY_DATA'
export const USER_TYPEDOC_DATA                        = 'user_typedoc_data'
export const USER_TYPEDOC_FT_DATA                     = 'user_typedoc_ft_data'
export const USER_TYPEDOC_FDS_DATA                    = 'user_typedoc_fds_data'
export const USER_TYPEDOC_OFFICIEL_DATA               = 'user_typedoc_officiel_data'
export const USER_TYPEDOC_FT_DATA_CLEAN               = 'user_typedoc_ft_data_clean'
export const USER_TYPEDOC_FDS_DATA_CLEAN              = 'user_typedoc_fds_data_clean'
export const USER_TYPEDOC_OFFICIEL_DATA_CLEAN         = 'user_typedoc_officiel_data_clean'
export const USER_TYPEDOC_RSE_DATA_CLEAN              = 'user_typedoc_rse_data_clean'
export const USER_TYPEDOC_RSE_DATA                    = 'user_typedoc_rse_data'

export const USER_TYPEDOC_PLAQUETTE_DATA_CLEAN        = 'user_typedoc_plaquette_data_clean'
export const USER_TYPEDOC_PLAQUETTE_DATA              = 'user_typedoc_plaquette_data'

export const USER_TYPEDOC_FORMATION_DATA_CLEAN        = 'user_typedoc_formation_data_clean'
export const USER_TYPEDOC_FORMATION_DATA              = 'user_typedoc_formation_data'

export const ITEMS_DATA_CLEAN                         = 'items_data_clean'

export const USER_TYPEDOC_DOCUMENTATION_DATA_CLEAN    = 'user_typedoc_documentation_data_clean'
export const USER_TYPEDOC_DOCUMENTATION_DATA          = 'user_typedoc_documentation_data'
