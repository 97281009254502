

var url_ressource = process.env.REACT_APP_URL_RESSOURCES;


if(url_ressource === undefined){
  if (process.env.REACT_APP_ENV === "FCD"){
    url_ressource = 'https://stagingpro.france-culinaire.com/ressources/images/'
  }
  else if (process.env.REACT_APP_ENV === "HYDRAPRO"){
  url_ressource = 'https://stagingpro.hydrapro.fr/ressources/images/'
  }
  else if (process.env.REACT_APP_ENV === "HYDRACHIM"){
    url_ressource = 'https://stagingpro.hydrachim.fr/ressources/images/'
  }
  else if (process.env.REACT_APP_ENV === "KEMIX"){
    url_ressource = 'https://stagingpro.kemix.fr/ressources/images/'
  }
  else if (process.env.REACT_APP_ENV === "developementDemoVideo"){
    url_ressource = 'https://stagingpro.hydrachim.fr/ressources/images/'
  }
  if (process.env.REACT_APP_ENV === "developement"){
    url_ressource = 'https://stagingpro.france-culinaire.com/ressources/images/'
  }
}

export default url_ressource;
