import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
//import CRM from "../CRM/MAIN/index";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import SignUp_lbe from "../SignUp_lbe";
import SignUp_formSalons from "../SignUp_formSalons";
import RefreshToken from "../RefreshToken";
import NewPassword from "../NewPassword";
import ResetPassword from "../ResetPassword";
import {setInitUrl, checkGUID} from "appRedux/actions/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";
import axios from 'util/Api';

import {INIT_STATE} from "appRedux/reducers/Auth";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import {
  getUser,
  getInvoice,
  getOrders,
  getDeliveryNote,
  getAddress,
  getDashboard,
} from "../../appRedux/actions/Auth";


import {
  getClients
} from "../../appRedux/actions/Clients";


//***************************************************************************
//-------------------------- STAFF USER ROUTE -------------------------------
//***************************************************************************

const StaffRestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;


//***************************************************************************
//-------------------------- Normal USER ROUTE ------------------------------
//***************************************************************************

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };


  componentWillMount() {
    console.log(this.props)
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);
    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      console.log(nextProps.token)
      axios.defaults.headers.common['Authorization'] = "Bearer " + nextProps.token;
    }
    console.log('----------------------------------------------')
    const splitedUrl = nextProps.initURL.split('/')
    //const IDCompte = splitedUrl[splitedUrl.length - 1]


    if (nextProps.token && nextProps.IDCompteAtlas && !nextProps.authUser) {
      this.props.getUser()
      this.props.getDashboard();
      this.props.getClients(INIT_STATE.orders_LIST_OFFSET, INIT_STATE.orders_LIST_QUANTITY)

    }else if (nextProps.token && !nextProps.authUser) {
      this.props.getUser()
      this.props.getOrders(INIT_STATE.orders_LIST_OFFSET, INIT_STATE.orders_LIST_QUANTITY, 0)
      this.props.getInvoice(INIT_STATE.invoice_LIST_OFFSET, INIT_STATE.invoice_LIST_QUANTITY)
      this.props.getAddress(INIT_STATE.address_LIST_OFFSET, INIT_STATE.address_LIST_QUANTITY)
      this.props.getDeliveryNote(INIT_STATE.deliverynote_LIST_OFFSET, INIT_STATE.deliverynote_LIST_QUANTITY)

      this.props.getDashboard();

    }


  }

  renderHelloWorld = () => {
    const splitedPath = this.props.location.pathname.split('/');
    const GUID = splitedPath[splitedPath.length - 1]
    var user = checkGUID(GUID)
    return (
      <Redirect to ="/"/>
    )
  }

  render() {
    const {match, location, themeType, layoutType, navStyle, locale, token, initURL} = this.props;

    if (themeType === THEME_TYPE_DARK) {
      //document.body.classList.add('dark-theme');
    }

    if (location.pathname === '/') {
      if (token === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/dashboard'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }


    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (

      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route path='/signin' component={SignIn}/>
            <Route path='/signup' component={SignUp}/>
            <Route path='/reset-password' component={ResetPassword}/>
            <Route path='/new-password' component={NewPassword}/>
            <Route path='/refresh-session' component={RefreshToken}/>
            <Route path='/signup_lbe' component={SignUp_lbe}/>
            <Route path='/SignUp_formSalons' component={SignUp_formSalons}/>

            <RestrictedRoute path={`${match.url}`} token={token}
                             component={MainApp}/>

          </Switch>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale, navStyle, themeType, layoutType} = settings;
  const {authUser, token, initURL, IDCompteAtlas, IDClientStaffSession} = auth;
  return {locale, token, navStyle, themeType, layoutType, authUser, initURL, IDCompteAtlas}
};


const setStaffSession = (IDClient) => {
  return (dispatch) => {
    dispatch({type:'STAFF_SESSION_CLIENT', payload:IDClient})
  }
};

export default connect(mapStateToProps, {
  setStaffSession,
  setInitUrl,
  getUser,
  getAddress,
  getDeliveryNote,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange,
  getInvoice,
  getOrders,
  getClients,
  getDashboard
})(App);
