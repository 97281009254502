import {Select} from "antd";
const Option = Select.Option;


export function getLibelleFromOptionsList(list, id) {
  for(let item in list) {
    if(list[item].props.value === id)
      return list[item].props.children;
  }
  return '';
}



/*
  Retourne 'true' si une variable est null, undefined ou '', et 'false' sinon
*/
export function isObjectNull(item) {
  if(item === undefined || item === null || item === '')
    return true;
  return false;
}

export function formatDate(x, y) {
  if(x.getTime() === 0)
    return null;
  if(x.getFullYear() === 1970)
    return '-'

  var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
  });

  return y.replace(/(y+)/g, function(v) {
      return x.getFullYear().toString().slice(-v.length)
  });
}


export function formatPourcentage(pourc) {
  return  pourc + "%" ;
}

export function formatCurrency(euros, pe_decimal = 2) {
  return ((euros*100) / 100).toFixed(pe_decimal) + "€"
}



export function getBoolToString(param) {
  return ((param === 1 || param === '1') ? 'OUI' : 'NON');
}

export function getBoolToColor(param) {
  return ((param === 1 || param === '1' || param === 'OUI') ? 'green' : 'blue');
}

export function hasUpperCase(str) {

   let i            = 0;
   let character    = '';
   let isUpperCase  = false;

   while (i <= str.length){
       character = str.charAt(i);
       if (!isNaN(character * 1)){
       }else{
           if (character == character.toUpperCase()) {
              if(!hasSpecialCaracters(character)){
               isUpperCase = true
             }
           }
       }
       i++;
   }
   return isUpperCase;
 }



 export function hasUpperCase2(str) {

   const strongRegex = new RegExp(`^(?=.*?[A-Z]).{1,}$`);
   if(!str.match(strongRegex) ) {
     return false;
   }else{
     return true;
   }
  }

  export function cleanJSONContent(data) {
    if(data === null || data === "" || data === undefined) {
      return [];
    }
    return JSON.parse(JSON.stringify(eval("(" + data + ")")))
  }


 export function hasSpecialCaracters(str) {

   const strongRegex = new RegExp(`^(?=.*?[#?!+=/.@$%^&*)-]).{1,}$`);
   if(!str.match(strongRegex) ) {
     return false;
   }else{
     return true;
   }
  }

  export function hasNumber(str) {

    const strongRegex = new RegExp(`^(?=.*?[0-9]).{1,}$`);
    if(!str.match(strongRegex) ) {
      return false;
    }else{
      return true;
    }
   }
