
import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../../constants/ThemeSetting";
import IntlMessages from "../../../util/IntlMessages";

const COMPANY_ADMIN = ({defaultOpenKeys, selectedKeys, themeType, IsChiffreMasque}) =>

<Menu
  defaultOpenKeys={[defaultOpenKeys]}
  selectedKeys={[selectedKeys]}
  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
  mode="inline">

  <Menu.Item key="dashboard">
    <Link to="/dashboard"><i className="icon icon-widgets"/>
      <IntlMessages id="sidebar.dashboard"/></Link>
  </Menu.Item>

  <Menu.Item key="bon_livraison">
    <Link to="/bon_livraison"><i className="icon icon-attachment"/>
      <IntlMessages id="sidebar.BL"/></Link>
  </Menu.Item>

  { IsChiffreMasque == 0 &&
    <Menu.Item key="facture">
      <Link to="facture"><i className="icon icon-orders"/>
        <IntlMessages id="sidebar.Factures"/></Link>
    </Menu.Item>
  }

  <Menu.Item key="moncompte">
    <Link to="/moncompte"><i className="icon icon-user"/>
      <IntlMessages id="sidebar.InfoPerso"/></Link>
  </Menu.Item>

</Menu>


export default COMPANY_ADMIN;
