import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {onNavStyleChange, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


// require("assets/images/logo.png")

class SidebarLogoBottom extends Component {

  render() {
    const {width, themeType} = this.props;
    let {navStyle} = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div style={{paddingTop : 80}} >
        <div className="gx-layout-sider-header" style={{padding : 50}} >
          <Link to="/" >
            {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
              <img alt="" src={require("assets/images/logo.png")}/> :
              themeType === THEME_TYPE_LITE ?
                <img alt="" src={require("assets/images/logo.png")}/> :
                <img alt="" src={require("assets/images/logo.png")}/>}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, width, navCollapsed} = settings;
  return {navStyle, themeType, width, navCollapsed}
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogoBottom);
