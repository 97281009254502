import React from "react";
import {Select, message} from "antd";
import axios from 'util/Api'

const Option = Select.Option;


////////////////////////////////////////////////////////////////////////////////
/////**************************** GETTER TOOLS *****************************/////
/////////////////////////////////////////////////////////////////////////////////

export async function postAction (url, item, messageContent='') {
  let dataToReturn = [];
  await axios.post(url, item
  ).then(({data}) => {
    messageContent = (messageContent === '' ? 'Modification réalisée avec succès' : messageContent);
    message.success(messageContent);
    dataToReturn = data;
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification');
  });
  return dataToReturn;
}

const downloadFile = (url, name) => {
  console.log(url);
  const messageIndicator = message.loading('En cours...', 0);

  axios.get(url, {responseType: 'blob'}
  ).then((response) => {
    setTimeout(messageIndicator, 10);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
   }).catch(error => {
     setTimeout(messageIndicator, 10);
     console.log(error)
   });
}

const getList = async (url, listType='', list=[]) => {

  console.log(url);
  await axios.get(url,
  ).then(({data}) => {
    //console.log("get_"+listType);
    if(listType === '')
      list = data;
    else
      list = list.concat(data[listType]);
  }).catch(function (error) {
    console.log("Error****:", error.message);
  });
  return list;
}

export const getListSuiviTechnique = (IDStatutSuiviTechnique, offset, search, suiviTechnique_LIST, id_compte = null) => {

  let url = id_compte ? `/suivitechnique/${IDStatutSuiviTechnique}/${offset}/${20}/${(search === '' ? '0' : search)}/suivitechnique?id_compte=${id_compte}` : `/suivitechnique/${IDStatutSuiviTechnique}/${offset}/${20}/${(search === '' ? '0' : search)}/suivitechnique`;
  return getList(url, 'suiviTechnique_LIST', suiviTechnique_LIST);
}

export const downloadSuiviTechniqueDocument = (pe_IDSuiviTechnique, pe_IDDocument, pe_fileNameDocument, id_compte = null) => {
  let url = id_compte ?  `/suivitechnique/${pe_IDSuiviTechnique}/${pe_IDDocument}/downloadSuivitechnique?id_compte=${id_compte}` : `/suivitechnique/${pe_IDSuiviTechnique}/${pe_IDDocument}/downloadSuivitechnique`;
  console.log(url);
  downloadFile(url, pe_fileNameDocument);
}

export const getListCollaborateur = (collaborateur_LIST, id_compte = null) => {

  const url = id_compte ? `/suivitechnique/collaborateur?id_compte=${id_compte}` : `/suivitechnique/collaborateur`
  return getList(url, 'collaborateur_LIST', collaborateur_LIST);
}


export const saveSuiviTechnique = async (suiviTechnique, id_compte) => {
  const url = id_compte ? `/suivitechnique/updateSuiviTechnique?id_compte=${id_compte}` : `/suivitechnique/updateSuiviTechnique`
  await postAction(url, {suiviTechnique: suiviTechnique});
}


export const saveClient = async (client) => {
  const url = `/client/updateClientFiche`
  await postAction(url, {client: client});
}


export const getListConsommationDashboard = async (consommation_LIST, mois, annee, id_compte = null) => {
  const url =  `dashboard/${mois}/${annee}/consommation`
  return getList(url, 'consommation_LIST', consommation_LIST);
}

export const getListConsommationTendanceDashboard = async (consommationTendance_LIST, mois, annee, id_compte = null) => {
  const url =  `dashboard/${mois}/${annee}/consommation`
  return getList(url, 'consommationTendance_LIST', consommationTendance_LIST);
}





/*
 export async function saveSuiviTechnique(suiviTechnique, id_compte) {

  const url = id_compte ? `/suivitechnique/updateSuiviTechnique?id_compte=${id_compte}` : `/suivitechnique/updateSuiviTechnique`

  await axios.post(url, {suiviTechnique: suiviTechnique}
  ).then(({data}) => {
    message.success('Suivi technique modifiée avec succès');
  }).catch(function (error) {
    console.log("Error****:", error.message);
    message.error('Erreur lors de la modification du suivi technique');
  })
}
*/


/////////////////////////////////////////////////////////////////////////////////
/////*********************** LIST GETTER TO OPTIONS ************************/////
/////////////////////////////////////////////////////////////////////////////////
export const getSalon = () => {
  var listSalon = [];
  listSalon.push(<Option key={1} value={1}>CFIA</Option>);
  return listSalon;
}

export const getRecuPar = () => {
  var listRecuPar = [];
  listRecuPar.push(<Option key={1} value={1}>Thomas FRETIN</Option>);
  listRecuPar.push(<Option key={1} value={1}>Steven DIARD</Option>);
  return listRecuPar;
}

export const getAPI_KEY_CAPTCHA = () => {
  let SITE_KEY = "6LfpL6YZAAAAAOSAjnOoZJ1hY1IxZ_bMwWFt-2ND"

  if (process.env.REACT_APP_ENV === "developement"){
    SITE_KEY= "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
  }
  return SITE_KEY;
}




/////////////////// FUNCTION ADMIN //////////////////////:
export const getListSuiviTechniqueStaff = (IDClient, offset, search, suiviTechnique_LIST) => {

  let url = `/suivitechniquestaff/${IDClient}/${offset}/${20}/${(search === '' ? '0' : search)}/suivitechnique`;
  console.log(url);
  return getList(url, 'suiviTechnique_LIST', suiviTechnique_LIST);
}

export const getListCollaborateurStaff = (collaborateur_LIST, IDClient) => {

  const url = `/suivitechniquestaff/${IDClient}/collaborateur`
  return getList(url, 'collaborateur_LIST', collaborateur_LIST);
}


export const saveSuiviTechniqueStaff = async (suiviTechnique) => {
  const url =  `/suivitechniquestaff/updateSuiviTechnique`
  console.log(url);
  await postAction(url, {suiviTechnique: suiviTechnique});
}

export const downloadSuiviTechniqueDocumentStaff = (pe_IDSuiviTechnique, pe_IDDocument, pe_fileNameDocument) => {
  let url =`/suivitechniquestaff/${pe_IDSuiviTechnique}/${pe_IDDocument}/downloadSuivitechnique`;
  console.log(url);
  downloadFile(url, pe_fileNameDocument);
}


export const getListClientStaff = (offset, isAllClient, search, client_LIST) => {

  let url = `/clientsstaff/${offset}/${20}/${isAllClient}/${(search === '' ? '0' : search)}/client`;
  console.log(url);
  return getList(url, 'client_LIST', client_LIST);
}
